import * as React from 'react';
import {
  Section,
  Button,
  Box,
  Text,
  Grid,
  Link,
  Suspense,
  SectionActions
} from '@churni/styleguide';
import { firestore } from 'firebase';

import { useCompany, Upgrade } from '../../containers';

export function SettingsBilling(): React.ReactElement {
  const company = useCompany();

  return (
    <Section title={'Billing'}>
      <Suspense>
        {company.billing.subscriptionID ? (
          <SubscriptionPanel />
        ) : (
          <NewSubscription />
        )}
      </Suspense>
    </Section>
  );
}

export function SubscriptionPanel(): React.ReactElement {
  const company = useCompany();
  const { openPopup } = Upgrade.useContainer();

  const {
    cancelURL,
    updateURL,
    productID: planName,
    quantity
  } = company.billing;

  const cancelSubscription = () => {
    if (!Paddle) {
      return;
    }
    Paddle.Checkout.open({
      override: cancelURL
    });
  };

  const updateBillingInfo = () => {
    if (!Paddle) {
      return;
    }
    Paddle.Checkout.open({
      override: updateURL
    });
  };

  return (
    <>
      <Grid columns={2} rowGap={3}>
        <Box>
          <Link
            as="span"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              openPopup();
            }}
            className={'billing-plan'}
          >
            <Text>
              <b>Plan:</b>{' '}
            </Text>
            <Text>
              {planName} <Text variant="body2">({quantity} sessions/mo)</Text>
            </Text>
          </Link>
        </Box>
      </Grid>
      <SectionActions>
        <Box>
          {/* {!cancelDate && ( */}
          <Button
            ml={-1}
            variant="text"
            className={'billing-cancel-button'}
            onClick={cancelSubscription}
          >
            Cancel subscription
          </Button>
          {/* )} */}
        </Box>
        <Box>
          <Button
            className={'billing-update-button'}
            variant="primaryText"
            onClick={updateBillingInfo}
          >
            Edit billing info
          </Button>
          <Button ml={2} className={'billing-cta'} onClick={openPopup}>
            Change Plan
          </Button>
        </Box>
      </SectionActions>
    </>
  );
}

export function NewSubscription(): React.ReactElement {
  const { openPopup } = Upgrade.useContainer();

  const company = useCompany();
  const cancellationDate =
    company.billing.cancellationDate &&
    new firestore.Timestamp(
      company.billing.cancellationDate.seconds,
      company.billing.cancellationDate.nanoseconds
    ).toDate();

  const isBeforeCancellationDate =
    cancellationDate && cancellationDate > new Date();

  return (
    <>
      <Box>
        {isBeforeCancellationDate && (
          <Box
            p={2}
            mb={2}
            sx={{
              borderRadius: 'rounded',
              bg: 'secondary',
              color: 'textOnSecondary'
            }}
          >
            Your subscription has been set to be canceled at the end of the
            billing cycle ({cancellationDate.toLocaleDateString()})
          </Box>
        )}
        <Box>
          <Text>
            <b>Plan:</b>{' '}
          </Text>
          <Text>
            {isBeforeCancellationDate ? (
              <>{company.billing.productID}</>
            ) : (
              <>None</>
            )}
          </Text>
        </Box>
      </Box>
      <Box pt={3}>
        <Box>
          <Button onClick={openPopup} className={'billing-upgrade'}>
            Upgrade
          </Button>
        </Box>
      </Box>
    </>
  );
}
