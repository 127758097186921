import { Box } from '@churni/styleguide';
import * as React from 'react';

import { Router, Redirect, routes, useLocation } from '@churni/common';
import { Account } from './Account';
import { SettingsMembers } from './General/SettingsMembers';
import { SettingsInformation } from './General/SettingsInformation';
import { DashboardHeader } from '../DashboardLayout';
import { SettingsBilling } from './Billing/SettingsBilling';
import { SettingsSetup } from './General/SettingsSetup';
import { SEO } from '../SEO';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelHeaderLink
} from '../NewDashboard/Panel';

export function Settings(): React.ReactElement {
  const { location } = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <SEO title={'Account Settings'} />
      <DashboardHeader title="Settings" />
      <Panel>
        <PanelHeader>
          <PanelHeaderLink
            active={!!pathname.match(/^\/settings\/info/)}
            route={routes.info()}
          >
            Information
          </PanelHeaderLink>
          <PanelHeaderLink
            active={!!pathname.match(/^\/settings\/account/)}
            route={routes.account()}
          >
            Account
          </PanelHeaderLink>
          <PanelHeaderLink
            active={!!pathname.match(/^\/settings\/billing/)}
            route={routes.billing()}
          >
            Billing
          </PanelHeaderLink>
        </PanelHeader>
        <PanelBody>
          <Router>
            <Info path={'/info'} />
            <SettingsBilling path={'/billing'} />
            <Account path={'/account'} />
            <Redirect from={'/'} to={routes.info()} noThrow={true} />
          </Router>
        </PanelBody>
      </Panel>
    </>
  );
}

export function Info(): React.ReactElement {
  return (
    <>
      <SettingsInformation />
      <Box mt={3}>
        <SettingsSetup />
      </Box>
      <Box mt={3}>
        <SettingsMembers />
      </Box>
    </>
  );
}
