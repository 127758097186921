/*
 * Get the URL of a suggested logo, for a given name, from clearbit
 */
import * as React from 'react';

/*
 * Fetch an image, throw an error if it's not found.
 */
async function loadImage(imageURL: string): Promise<void> {
  const res = await fetch(imageURL);

  if (!res.ok) {
    throw new Error(res.statusText);
  }
}

async function getSuggestedLogo(name: string): Promise<string | undefined> {
  const res = await fetch(
    `https://autocomplete.clearbit.com/v1/companies/suggest?query=${name}`
  );
  const [suggestion] = await res.json();

  if (!suggestion) {
    return;
  }

  return `${suggestion.logo}?size=200`;
}

/*
 * Return the logoURL by fetching Clearbit's suggestions from the name.
 */
export function useLogoURL(
  name: string
): [string | null, (logoURL: string | null) => void] {
  const [logoURL, setLogoURL] = React.useState<string | null>(null);
  const latestName = React.useRef(name);
  const latestLogoURL = React.useRef(logoURL);

  latestLogoURL.current = logoURL;
  latestName.current = name;

  React.useEffect(() => {
    // Unset logoURL when the name is empty
    if (!name) {
      setLogoURL(null);
      return;
    }

    // Ask Clearbit for a logo matching the name
    (async () => {
      try {
        const newLogoURL = await getSuggestedLogo(name);
        if (!newLogoURL) {
          return;
        }

        // Sometimes Clearbit returns an image that resolves to a 404
        // to prevent that we try to load the image before actually using it.
        await loadImage(newLogoURL);

        if (name !== latestName.current || logoURL !== latestLogoURL.current) {
          // This request has become out of sync and should be cancelled
          return;
        }

        setLogoURL(newLogoURL);
      } catch (err) {
        // We can ignore Clearbit errors
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return [logoURL, setLogoURL];
}
