import * as React from 'react';
import { Icon, Button, Box, Flex } from '@churni/styleguide';
import { useAuth } from '@churni/fire';
import { useGoogleOneTap } from './useGoogleOneTap';
import { User } from '@churni/core';
import { routes } from './router';

export function GoogleButtonWithOneTap(props: {}): React.ReactElement {
  const { shouldShowFallbackButton, loading } = useGoogleOneTap(false, 'use');

  return (
    <>
      <Flex flex={1}>
        <Box>
          <Box
            id="google_one_tap"
            style={{
              display: shouldShowFallbackButton ? 'none' : 'block',
              marginLeft: '-28px'
            }}
          />
        </Box>

        <GoogleButton
          sx={{
            display: [
              'flex',
              shouldShowFallbackButton || loading ? 'flex' : 'none'
            ]
          }}
        />
      </Flex>
    </>
  );
}

export function GoogleButton(props: any): React.ReactElement {
  const { onLogin } = useGoogleLogin();

  return (
    <Button
      flex={1}
      onClick={onLogin}
      className="signin-google"
      icon={<Icon.GoogleLogo />}
      {...props}
    >
      Continue with Google
    </Button>
  );
}

export function ExternalGoogleButton(props: any): React.ReactElement {
  return (
    <Button
      onClick={() => {
        window.location.href = routes.toAppURL(
          routes.googleSSO({ login: 'google' })
        );
      }}
      className="signin-google"
      icon={<Icon.GoogleLogo />}
      {...props}
    >
      Continue with Google
    </Button>
  );
}

export function useGoogleLogin() {
  const auth = useAuth;
  const provider = new auth.GoogleAuthProvider();
  const authInstance = useAuth();

  const onLogin = async () => {
    await User.loginWithProvider(authInstance)(provider);
  };

  return {
    onLogin
  };
}

export function GoogleOneTap(props: {}): React.ReactElement {
  useGoogleOneTap();

  return (
    <Box sx={{ zIndex: 2, position: 'relative' }}>
      <Box id="google_one_tap" />
    </Box>
  );
}
