import * as React from 'react';
import { Box, Flex, Text } from '@churni/styleguide';

export function DashboardHeader(props: {
  title: string;

  warning?: React.ReactElement;
  children: React.ReactElement;
}): React.ReactElement {
  const { title, warning = null, children } = props;
  return (
    <Box mb={2} sx={{ minHeight: 56 }}>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
        <Box sx={{ alignSelf: 'top', ml: [0, 0, 5] }}>
          <Text variant={'display4'}>{title}</Text>
        </Box>
        <Box mt={'auto'}>{children}</Box>
      </Flex>
      {warning}
    </Box>
  );
}
