import * as React from 'react';
import { Box, Text } from '@churni/styleguide';
import { PieChart } from '../common';
import { routes } from '@churni/common';
import { navigate } from '@churni/common';

export function QuestionCard(props: { question: any }): React.ReactElement {
  const { question, ...rest } = props;

  const answers =
    question.infos.type === 'text' ? question.answer : question.answers;

  const labels = answers.map(answer => answer.key);
  const data = answers.map(answer => answer.doc_count);

  const onClick = (index: number) => {
    navigate(
      routes.customers({
        filters: {
          questions: [{ key: question.key, value: labels[index] }]
        }
      })
    );
  };

  if (!labels.length) {
    return null;
  }

  return (
    <Box
      key={question.key}
      sx={{ minHeight: 350, cursor: 'pointer' }}
      elevation={2}
      p={3}
      {...rest}
    >
      <Box mb={2}>
        <Text variant={'heading1'}>{question.infos.title} </Text>
      </Box>
      <PieChart
        size={250}
        labelTemplate={'auto 100px'}
        direction={['column', 'column', 'row']}
        labels={labels}
        data={data}
        onClick={onClick}
      />
    </Box>
  );
}
