/*
 * Alternative to react-cache while it's still broken.
 */

export function createCache<Input, Result>(
  lookup: (input: Input) => Promise<Result>,
  getCacheKey: (input: Input) => string = input => input.toString()
): {
  write: (input: Input, r: Result) => void;
  readMultiple: (inputs: Input[]) => Result[];
  read: (input: Input) => Result;
} {
  const cache: { [key: string]: any } = {};

  function write(input: Input, result: Result) {
    cache[getCacheKey(input)] = {
      result
    };
  }

  function read(input: Input): Result {
    const cacheKey = getCacheKey(input);

    if (!cache[cacheKey]) {
      const pending = lookup(input).then(
        result => {
          cache[cacheKey] = {
            result
          };
        },
        error => {
          cache[cacheKey] = {
            error
          };
        }
      );

      cache[cacheKey] = {
        pending
      };
    }

    return cache[cacheKey];
  }

  return {
    write,
    read
  };
}
