import {
  RevisionInterface,
  FormInterface,
  Form as FormModel,
  Revision,
  Company
} from '@churni/core';

import { useDocument, useDatabase } from '@churni/fire';
import * as React from 'react';
import {
  Box,
  Button,
  Text,
  Flex,
  Switch,
  Icon,
  Suspense,
  Tooltip
} from '@churni/styleguide';

import { useCompany, useEnvironment, FormState } from '../containers';

import { DashboardHeader } from '../DashboardLayout';
import { demoValue, useSessionState } from '@churni/common';
import { PreviewForm } from '../DashboardLayout/PreviewForm';

import { useAlertOnExit } from './useAlertOnExit';
import * as isEqual from 'lodash.isequal';
import { SEO } from '../SEO';

import { Panel, PanelBodyLoading } from '../NewDashboard/Panel';

const Editor = React.lazy(() =>
  import('@churni/editor' /* webpackChunkName: "editor" */)
);

export function FormEditorDemo(props: {}): React.ReactElement {
  return (
    <>
      <SEO title={'Cancel Flow'} />
      <Suspense
        fallback={
          <>
            <DashboardHeader title="Cancel Flow" />
            <Panel>
              <PanelBodyLoading />
            </Panel>
          </>
        }
      >
        <FormEditorContent
          form={null}
          isDemo={true}
          revisionValue={demoValue}
        />
      </Suspense>
    </>
  );
}

export function FormEditor(props: {}): React.ReactElement {
  return (
    <>
      <SEO title={'Cancel Flow'} />
      <Suspense
        fallback={
          <>
            <DashboardHeader title="Cancel Flow" />
            <Panel>
              <PanelBodyLoading />
            </Panel>
          </>
        }
      >
        <FormEditorFetch />
      </Suspense>
    </>
  );
}

export function FormEditorFetch(): React.ReactElement {
  const company = useCompany();

  const db = useDatabase();
  const environment = useEnvironment();

  const form = useDocument<FormInterface>(
    FormModel.queryByID(db)(company.id, environment.defaultFormID)
  );

  const revision = useDocument<RevisionInterface>(
    Revision.queryByID(db)(company.id, form.id, form.primaryRevision)
  );

  return (
    <FormEditorContent
      form={form}
      isDemo={false}
      revisionValue={revision.value}
    />
  );
}

export function FormEditorContent(props: {
  form: FormInterface;
  isDemo: boolean;
  revisionValue: object[];
}) {
  const { isDemo, form, revisionValue } = props;

  const company = useCompany();
  const db = useDatabase();
  const { hasChanged, setChanged } = FormState.useContainer();
  const [mode, setMode] = React.useState('edit');

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const pageState = useSessionState(
    async () => {
      alert('Survey completed & redirect to cancelConfirmationURL');
    },
    async () => {
      alert('Survey abandoned & redirect to saveReturnURL');
    }
  );

  const [editValue, setEditValue] = React.useState(revisionValue);

  const ref = React.useRef();

  const onChange = (newValue: any) => {
    if (mode === 'answer') {
      return;
    }
    const isValueDifferent = !isEqual(newValue, revisionValue);
    setChanged(isValueDifferent);
  };

  const onSave = async (props: { formID: string; variables: any }) => {
    const value = ref.current.getValue();
    const savedValue = JSON.parse(JSON.stringify(value));
    setChanged(false);
    await FormModel.updateFormContent(db)(company.id, form.id, savedValue);
    if (['todo', 'skip'].includes(company.onboarding.survey)) {
      await Company.queryByID(db)(company.id).update({
        onboarding: {
          ...company.onboarding,
          survey: 'done'
        }
      });
    }
  };

  useAlertOnExit(isDemo);

  React.useEffect(() => {
    if (pageState.currentPage) {
      pageState.reset();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // When switching from edit to answer mode. save edit value and reset answer value to new value
  // When switching from answer to edit. reset editor value to previous saved value
  const toggleMode = () => {
    const newValue = ref.current.getValue();
    pageState.setErrors([]);
    if (mode === 'edit') {
      setEditValue(newValue);
      setMode('answer');
      return;
    }
    setMode('edit');
    ref.current.setValue(editValue);
  };

  return (
    <Box>
      <DashboardHeader title="Cancel Flow" sx={{ borderBottom: 0 }}>
        <Flex alignItems="center">
          <Flex sx={{ flexDirection: ['column', 'row'] }}>
            <PreviewForm />

            {!isDemo && (
              <Tooltip content={'Toggle edit mode : preview non-saved changes'}>
                <Flex alignItems="center" ml={[0, 3]} mt={[1, 0]}>
                  <Icon.Edit mr={1} />
                  <Text variant="subtitle3">Edit mode</Text>
                  <Switch
                    className={'editor-edit-mode'}
                    ml={1}
                    mr={2}
                    onClick={toggleMode}
                    checked={mode !== 'answer'}
                    sx={{ cursor: 'pointer' }}
                  />
                </Flex>
              </Tooltip>
            )}
          </Flex>

          <Box ml={3}>
            <Button
              className={'editor-save'}
              variant={'primary'}
              onClick={onSave}
              disabled={!hasChanged || isDemo}
            >
              Save
            </Button>
          </Box>
        </Flex>
      </DashboardHeader>

      <Panel>
        <Editor
          ref={ref}
          onChange={onChange}
          mode={mode}
          initialValue={revisionValue}
          page={pageState}
          company={{
            name: company.title
          }}
        />
      </Panel>
    </Box>
  );
}
