import * as React from 'react';
import { Box, Flex, Text, Icon, Link, Input, Form } from '@churni/styleguide';
import { navigate, routes } from '@churni/common';
import { useSearchParams } from './useSearchParams';
import { useCustomersResourceFromContext } from './useCustomersResourceFromContext';
import { SearchContext } from './SearchContext';

export function Filters(): React.ReactElement {
  const { customersResource } = useCustomersResourceFromContext();
  const {
    data: { saveReason, status, subscriptionLength, variables, questions }
  } = customersResource.read();

  const { filters } = React.useContext(SearchContext);

  return (
    <Box>
      <AggFilter title="Status" mb={3}>
        {status.map(status => (
          <AggElement
            ml={1}
            key={status.key}
            count={status.doc_count}
            filter={{ status: status.key }}
            active={filters.status === status.key}
          >
            {status.key}
          </AggElement>
        ))}
      </AggFilter>

      {saveReason.length > 0 && (
        <AggFilter title="Save Reason" mb={3}>
          {saveReason.map(reason => (
            <AggElement
              ml={1}
              key={reason.key}
              count={reason.doc_count}
              filter={{ saveReason: reason.key }}
              active={filters.saveReason === reason.key}
            >
              {reason.key}
            </AggElement>
          ))}
        </AggFilter>
      )}

      <SubscriptionAgg subscriptionLength={subscriptionLength} mb={3} />

      <AggFilter
        title={'Questions'}
        defaultCollapsed={!filters.questions}
        mb={3}
      >
        <Box mt={1}>
          {questions.map(question => {
            const buckets = question.answers;

            if (question.infos.type === 'text') {
              return <TextAggFilter question={question} filters={filters} />;
            }

            if (!question.answers.length) {
              return null;
            }

            return (
              <AggFilter
                title={question.infos.title}
                textVariant="body2"
                defaultCollapsed={
                  !(
                    filters.questions &&
                    filters.questions.find(q => q.key === question.key)
                  )
                }
                ml={1}
                mb={1}
              >
                <Box mt={1}>
                  {buckets.map(bucket => {
                    return (
                      <AggElement
                        ml={2}
                        count={bucket.doc_count}
                        filter={{
                          questions: [{ key: question.key, value: bucket.key }]
                        }}
                        active={
                          filters.questions &&
                          filters.questions.find(
                            q =>
                              q.key === question.key && q.value === bucket.key
                          )
                        }
                      >
                        {bucket.key}
                      </AggElement>
                    );
                  })}
                </Box>
              </AggFilter>
            );
          })}
        </Box>
      </AggFilter>

      <AggFilter title={'Variables'} defaultCollapsed={!filters.variables}>
        {variables.map(variable => {
          return (
            <AggFilter
              textVariant="body2"
              title={variable.key}
              ml={1}
              mb={1}
              defaultCollapsed={
                !(
                  filters.variables &&
                  filters.variables.find(v => v.key === variable.key)
                )
              }
            >
              <Box mt={1}>
                {variable.value.buckets.map(bucket => (
                  <AggElement
                    ml={2}
                    count={bucket.doc_count}
                    filter={{
                      variables: [{ key: variable.key, value: bucket.key }]
                    }}
                    active={
                      filters.variables &&
                      filters.variables.find(
                        v => v.key === variable.key && v.value === bucket.key
                      )
                    }
                  >
                    {bucket.key}
                  </AggElement>
                ))}
              </Box>
            </AggFilter>
          );
        })}
      </AggFilter>
    </Box>
  );
}

export function TextAggFilter(props: { question: any; filters: any }) {
  const { question, filters } = props;
  const questionFilter =
    filters.questions && filters.questions.find(q => q.key === question.key);
  const { search, periodicity, from, to } = useSearchParams();

  const [questionSearch, setQuestionSearch] = React.useState(
    questionFilter ? questionFilter.search : ''
  );

  const triggerSearch = () => {
    navigate(
      routes.customers({
        from,
        to,
        search,
        periodicity,
        filters: {
          ...filters,

          questions: [{ key: question.key, search: questionSearch }]
        }
      })
    );
  };

  React.useEffect(() => {
    if (!questionFilter) {
      setQuestionSearch('');
      return;
    }
  }, [questionFilter]);

  return (
    <AggFilter
      title={question.infos.title}
      textVariant="body2"
      defaultCollapsed={
        !(
          filters.questions &&
          filters.questions.find(q => q.key === question.key)
        )
      }
      ml={1}
      mb={1}
    >
      <Form onSubmit={triggerSearch}>
        <Input
          placeholder={'Search (answer)'}
          value={questionSearch}
          onChangeValue={setQuestionSearch}
          onBlur={triggerSearch}
        />
      </Form>
    </AggFilter>
  );
}

export function AggElement(props: {
  children: any;
  count: any;
  filter: any;
  active: boolean;
}): React.ReactElement {
  const { children, count, filter, active, ...rest } = props;

  const { search, periodicity, from, to } = useSearchParams();

  // https://github.com/facebook/react/issues/17272
  const [startTransition] = React.useTransition({ timeoutMs: 5500 });

  const { filters } = React.useContext(SearchContext);

  const onClick = () => {
    startTransition(() => {
      const newURL = routes.customers({
        search,
        periodicity,
        filters: { ...filters, ...filter }
      });
      navigate(newURL);
    });
  };

  const content = (
    <Flex {...rest} mb={'4px'} onClick={onClick}>
      <Box
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        <Text variant="body2" color={active ? 'primary' : 'text'}>
          {children}
        </Text>
      </Box>
      <Box ml={1}>
        <Text variant="body2" color={active ? 'primary' : 'text'}>
          ({count})
        </Text>
      </Box>
    </Flex>
  );

  if (filter) {
    const newFilters = { ...filters, ...filter };
    return (
      <Link
        to={routes.customers({
          from,
          to,
          search,
          periodicity,
          filters: newFilters
        })}
        onClick={onClick}
      >
        {content}
      </Link>
    );
  }

  return content;
}

export function AggFilter(props: {
  title: string;
  textVariant: string;
  defaultCollapsed?: boolean;
  children: React.ReactElement;
}): React.ReactElement {
  const {
    title,
    children,
    textVariant = 'subtitle4',
    defaultCollapsed = false,
    ...rest
  } = props;

  const [collapsed, setCollapsed] = React.useState(defaultCollapsed);

  const onClick = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Box {...rest}>
      <Flex alignItems={'center'} onClick={onClick} sx={{ cursor: 'pointer' }}>
        <Text
          variant={textVariant}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {title}
        </Text>
        <Box>{collapsed ? <Icon.ChevronDown /> : <Icon.ChevronUp />}</Box>
      </Flex>
      {!collapsed && children}
    </Box>
  );
}

export function SubscriptionAgg(props: {
  subscriptionLength: any;
}): React.ReactElement {
  const { subscriptionLength, ...rest } = props;
  const { filters } = React.useContext(SearchContext);

  return (
    <AggFilter title="Subscription Length" {...rest}>
      {subscriptionLength.map(subLength => {
        if (!subLength.doc_count) {
          return null;
        }

        if (subLength.to && !subLength.from) {
          return (
            <AggElement
              ml={1}
              key={subLength.key}
              count={subLength.doc_count}
              filter={{
                subLength: {
                  to: subLength.to
                }
              }}
              active={
                filters.subLength && filters.subLength.to === subLength.to
              }
            >
              Up to {subLength.to} month
            </AggElement>
          );
        }

        if (!subLength.to && subLength.from) {
          return (
            <AggElement
              ml={1}
              key={subLength.key}
              count={subLength.doc_count}
              filter={{
                subLength: {
                  from: subLength.from
                }
              }}
              active={
                filters.subLength && filters.subLength.from === subLength.from
              }
            >
              {subLength.from / 12} years+
            </AggElement>
          );
        }

        if (subLength.to >= 12 && subLength.from >= 12) {
          return (
            <AggElement
              ml={1}
              key={subLength.key}
              count={subLength.doc_count}
              filter={{
                subLength: {
                  from: subLength.from,
                  to: subLength.to
                }
              }}
              active={
                filters.subLength &&
                filters.subLength.from === subLength.from &&
                filters.subLength.to === subLength.to
              }
            >
              {subLength.from / 12} - {subLength.to / 12} years
            </AggElement>
          );
        }

        if (subLength.to && subLength.from) {
          return (
            <AggElement
              ml={1}
              key={subLength.key}
              count={subLength.doc_count}
              filter={{
                subLength: {
                  from: subLength.from,
                  to: subLength.to
                }
              }}
              active={
                filters.subLength &&
                filters.subLength.from === subLength.from &&
                filters.subLength.to === subLength.to
              }
            >
              {subLength.from} - {subLength.to} months
            </AggElement>
          );
        }

        return null;
      })}
    </AggFilter>
  );
}
