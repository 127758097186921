import * as React from 'react';

import { Router } from '@churni/common';
import { Customers } from './Customers';
import { Customer } from './Customer';

export function CustomersRouter(): React.ReactElement {
  return (
    <Router>
      <Customers path={'/'} />
      <Customer path={'/:id'} />
    </Router>
  );
}
