import * as React from 'react';
import { MemberInterface } from '@churni/core';

export const MembershipContext = React.createContext<MemberInterface | null>(
  null
);

export const useMembership = () => {
  return React.useContext(MembershipContext);
};
