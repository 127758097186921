import { User } from '@churni/core';
import { Box, Button, Flex, Form, Input, Link, Text } from '@churni/styleguide';

import { analytics, routes } from '@churni/common';
import { useAuth, useFirebase } from '@churni/fire';
import * as React from 'react';
import * as querystring from 'querystring';
import { LoginLayout } from './LoginLayout';
import { SEO } from '../SEO';
import { GoogleButtonWithOneTap } from '@churni/common';

export function Signup(props: any): React.ReactElement {
  const { location } = props;
  const { email: qsEmail = '', continueURL = '' } = querystring.parse(
    location.search.slice(1)
  );

  const authInstance = useAuth();

  const firebase = useFirebase();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(qsEmail);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState<null | string>(null);

  const onEmailLogin = async () => {
    try {
      setError(null);
      setLoading(true);
      await User.signupWithEmail(authInstance)(email, password);
      dataLayer.push({
        event: 'signup',
        type: 'email'
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO title={'Sign up'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Text variant="heading2">Create your free account</Text>
              </Box>
            </Flex>

            <Flex mt={3}>
              <GoogleButtonWithOneTap />
            </Flex>

            <Box
              mt={2}
              mb={1}
              sx={{ borderTop: 1, borderColor: 'border', width: '100%' }}
            />
            <Box pb={2} sx={{ textAlign: 'center' }}>
              <Text color={'secondaryText'}>or</Text>
            </Box>

            <Form onSubmit={onEmailLogin} className="signup-email-form">
              <Box pb={2}>
                <Input
                  focus
                  bg="white"
                  placeholder="Email"
                  value={email}
                  onChangeValue={setEmail}
                />
              </Box>
              <Box pb={2}>
                <Input
                  bg="white"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChangeValue={setPassword}
                />
              </Box>
              <Flex>
                <Button
                  className="signup-email"
                  flex={1}
                  type="submit"
                  variant="primaryOutline"
                  loading={loading}
                >
                  <Text>Sign up with Email</Text>
                </Button>
              </Flex>
            </Form>
          </Box>
        }
        footer={
          <Box mt={2}>
            <Flex></Flex>

            <Box mt={1}>
              <Text variant={'body2'}>
                By creating an account, you agree to the{' '}
                <Link
                  as="a"
                  href={routes.toWebsiteURL('/terms')}
                  variant="primary"
                >
                  Terms of Service.
                </Link>
              </Text>
            </Box>

            <Box mt={3}>
              <Text variant={'body2'}>Already have an account?</Text>
            </Box>
            <Box>
              <Link to={routes.login({ email, continueURL })}>
                <Text variant={'body2'} color="primary">
                  Sign in
                </Text>
              </Link>
            </Box>
          </Box>
        }
      />
    </>
  );
}
