import * as React from 'react';
import { Box, Select, Flex, Text } from '@churni/styleguide';
import { Periodicity } from '../../containers';

export interface PeriodicityParams {
  startAt: Date;
  endAt: Date;
  periodicity: 'week' | 'month' | 'quarter' | 'year';
  setPeriodicity: (periodicity: string) => void;
  interval: 'day' | 'week' | 'month';
}

export function PeriodicityPicker(): React.ReactElement {
  const {
    startAt,
    endAt,
    periodicity,
    setPeriodicity
  } = Periodicity.useContainer();
  return (
    <Flex flexDirection={'column'} alignItems={'flex-end'}>
      <Box>
        <Select
          value={periodicity}
          onChangeValue={setPeriodicity}
          className="periodicity-select"
        >
          <option value={'week'}>Last week</option>
          <option value={'month'}>Last Month</option>
          <option value={'quarter'}>Last Quarter</option>
          <option value={'year'}>Last Year</option>
        </Select>
      </Box>
      <Box>
        <Text variant="body3">
          {startAt.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short'
          })}{' '}
          -{' '}
          {endAt.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short'
          })}
        </Text>
      </Box>
    </Flex>
  );
}
