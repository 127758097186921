import * as firebasePackage from 'firebase';
import { createCache } from './createFunctionsCache';

interface InputParams {
  firebase: firebasePackage.app;
  name: string;
  data: object;
}

const queryCache = createCache<any>(
  (input: InputParams) => {
    const { firebase, name, data } = input;

    return firebase.functions().httpsCallable(name)(data);
  },
  input => {
    const { name, data } = input;

    return `${name}-${JSON.stringify(data)}`;
  }
);

/*
 * Use callable functions from firebase
 */
export function useFunctions<V>(
  firebase: firebasePackage.app,
  name: string,
  data?: any
): V | null {
  // firebase.functions().useFunctionsEmulator('http://localhost:8888');
  const promise = queryCache.read({ firebase, name, data });

  if (promise.pending) {
    throw promise.pending;
  }

  if (promise.error) {
    throw promise.error;
  }

  return promise.result;
}

/*
 * Experimental
 * Use is as a react resource for async suspense
 *
    const [resource, setResource] = React.useState(
    useFunctionsResource(firebase, 'search-dashboard', {
      companyID: company.id,
      environmentID: 'production',
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      interval
    })
  );

  const {
    data: { questions, sessions }
  } = resource.read();

  ...

  onClick(() => useTransition(setResource(useFunctionResource...))


 */
export function functionsResource(
  firebase: firebasePackage.app,
  name: string,
  data?: any
) {
  // firebase.functions().useFunctionsEmulator('http://localhost:8888');

  const read = () => {
    const promise = queryCache.read({ firebase, name, data });

    if (promise.pending) {
      throw promise.pending;
    }

    if (promise.error) {
      throw promise.error;
    }

    return promise.result;
  };

  return {
    read
  };
}
