import { firestore } from 'firebase';
import { genID, setDocument, getDocument } from '../helpers';
import { Company } from './Company';

export interface MemberInterface {
  id: string;
  companyID: string;
  role: 'admin' | 'member';
  inviteID: string;
  email: string;
}

export const Member = {
  create(form: Partial<MemberInterface> = {}): MemberInterface {
    const defaultValues: MemberInterface = {
      id: genID('comp'),
      companyID: '',
      role: 'member',
      inviteID: '',
      email: ''
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByCompany(db: firestore.Firestore) {
    return (companyID: string) => {
      return Company.queryByID(db)(companyID).collection('members');
    };
  },

  queryByID(db: firestore.Firestore) {
    return (companyID: string, memberID: string) => {
      return Company.queryByID(db)(companyID)
        .collection('members')
        .doc(memberID);
    };
  },

  queryByUser(db: firestore.Firestore) {
    return (userID: string) => {
      return db.collectionGroup('members').where('id', '==', userID);
    };
  },

  /*
   * Join a company.
   */
  joinAsMember(db: firestore.Firestore) {
    return async (
      companyID: string,
      userId: string,
      email: string,
      inviteID?: string
    ): Promise<void> => {
      const member = Member.create({
        id: userId,
        role: 'member',
        email,
        companyID
      });

      if (inviteID) {
        member.inviteID = inviteID;
      }

      await setDocument(Member.queryByID(db)(companyID, member.id), member);

      const company = await getDocument<CompanyInterface>(
        Company.queryByID(db)(companyID)
      );
      if (['todo', 'skip'].includes(company.onboarding.invite)) {
        await Company.queryByID(db)(companyID).update({
          onboarding: {
            ...company.onboarding,
            invite: 'done'
          }
        });
      }
    };
  }
};
