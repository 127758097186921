import { User } from '@churni/core';
import {
  Box,
  Button,
  Form,
  FormGroup,
  Input,
  Loading,
  Modal,
  Text,
  Popup
} from '@churni/styleguide';
import { useAuth } from '@churni/fire';
import * as React from 'react';
import { auth } from 'firebase';

export function ReauthenticatePopup(props: {
  onClose: () => void;
  onSuccess: () => void;
}): React.ReactElement {
  const authInstance = useAuth();

  const { onClose, onSuccess } = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [password, setPassword] = React.useState('');

  const onSubmit = async () => {
    try {
      setError(null);
      const credential = auth.EmailAuthProvider.credential(
        authInstance.currentUser.email,
        password
      );
      await User.reauthenticate(authInstance)(credential);
      onSuccess();
    } catch (err) {
      setError(err);
    }
  };

  return (
    <Popup onClose={onClose}>
      <Modal>
        <Box>
          <Box>
            <Box>
              <Text variant="heading1">Reauthenticate yourself</Text>
            </Box>
          </Box>
          <Form onSubmit={onSubmit}>
            <FormGroup title={'Password'} error={error}>
              <Input
                secureTextEntry
                value={password}
                type="password"
                onChangeValue={setPassword}
              />
            </FormGroup>

            <Box mt={1}>
              <Button
                kind="primary"
                onClick={onSubmit}
                className={'account-reauthenticate'}
              >
                {loading ? <Loading /> : <Text>Reauthenticate</Text>}
              </Button>
            </Box>
          </Form>
        </Box>
      </Modal>
    </Popup>
  );
}
