import * as React from 'react';
import {
  Popup,
  Box,
  Modal,
  Button,
  Flex,
  Text,
  Suspense,
  Link
} from '@churni/styleguide';
import { Snippet } from './Snippet';
import { ToggleEnvironment } from '../ToggleEnvironment';
import { Environment } from '../containers';

export function SetupModal(props: { onClose: () => void }): React.ReactElement {
  const { onClose } = props;

  const { environmentID } = Environment.useContainer();

  return (
    <Popup onClose={onClose}>
      <Box>
        <Modal sx={{ maxWidth: 700 }}>
          <Modal.Header>
            <Flex alignItems="baseline" justifyContent="space-between">
              <Box>Install Churni</Box>
              <Text variant="body1">
                <ToggleEnvironment />
              </Text>
            </Flex>
          </Modal.Header>
          <Modal.Body>
            <Text variant="subtitle2" color={'text'}>
              <Box>
                The installation of Churni on your product requires a technical
                installation on your side (It should takes a few minutes). You
                will need to redirect the customer to your cancellation flow.
                You can find more info{' '}
                <Link
                  as="a"
                  variant="primary"
                  href="https://www.churni.io/docs/installing-churni/"
                >
                  here
                </Link>
                .
              </Box>
              <Box mt={3}>
                <Text variant="body1">
                  Here's a example of how it can be done in javascript:
                </Text>
              </Box>
            </Text>

            <Suspense>
              <Snippet environmentID={environmentID} />
            </Suspense>
          </Modal.Body>
          <Modal.Footer>
            <Box />
            <Button
              variant={'cancel'}
              onClick={onClose}
              className={'close-setup'}
            >
              {`Close`}
            </Button>
          </Modal.Footer>
        </Modal>
      </Box>
    </Popup>
  );
}
