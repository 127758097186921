import * as React from 'react';
import { Box, Flex, Loading } from '@churni/styleguide';

export function AppLoading(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const { children } = props;

  return (
    <Flex flexDirection={'column'}>
      {children}
      <Box mt={11}>
        <Loading />
      </Box>
    </Flex>
  );
}
