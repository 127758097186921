import { Location } from 'history';
import * as querystring from 'querystring';
import * as React from 'react';

import { AuthVerifyEmail } from './AuthVerifyEmail';
import { AuthRecoverEmail } from './AuthRecoverEmail';
import { AuthResetPassword } from './AuthResetPassword';
import { Redirect } from '@churni/common';
import { LoginLayout } from './LoginLayout';
import { useAuthUser } from '@churni/fire';

/*
 * Route to handle auth action from firebase.
 */
export function AuthRouteAction(props: {
  location: Location;
}): React.ReactElement {
  const { location } = props;
  const user = useAuthUser();

  const { actionCode, action } = React.useMemo(() => {
    const qsParams = location.search
      ? querystring.parse(location.search.slice(1))
      : {};

    return {
      actionCode: qsParams.oobCode,
      action: qsParams.mode
    };
  }, [location]);

  if (action === 'resetPassword' && actionCode) {
    return <AuthResetPassword action={action} actionCode={actionCode} />;
  }
  if (action === 'recoverEmail' && actionCode) {
    return <AuthRecoverEmail action={action} actionCode={actionCode} />;
  }
  if (action === 'verifyEmail' && actionCode) {
    if (!user) {
      const URL = encodeURIComponent(`${location.pathname}${location.search}`);
      return <Redirect to={`/login?location=${URL}`} noThrow={true} />;
    }

    return <AuthVerifyEmail action={action} actionCode={actionCode} />;
  }

  return <LoginLayout body={'The URL you are accesing is invalid.'} />;
}
