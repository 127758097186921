import * as React from 'react';
import { useFirebase, functionsResource } from '@churni/fire';
import { PeriodicityParams } from './PeriodicityPicker';
import { useCompany, Environment } from '../../containers';

export function useQuestionResource(props: {
  periodicityParams: PeriodicityParams;
  questionID: string;
  search: string;
  offset: number;
}) {
  const [startTransition] = React.useTransition({ timeoutMs: 5200 });
  const { startAt, endAt, periodicity } = props.periodicityParams;
  const { questionID, search, offset } = props;

  const firebase = useFirebase();
  const company = useCompany();

  const { environmentID: originalEnvironment } = Environment.useContainer();

  const environmentID = company.id === 'demo' ? 'demo' : originalEnvironment;
  const companyID = company.id;

  const [questionResource, setQuestionResource] = React.useState(
    functionsResource(firebase, 'search-sessions', {
      type: 'question',
      companyID,
      environmentID,
      questionID,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      search,
      offset
    })
  );

  React.useEffect(() => {
    startTransition(() => {
      setQuestionResource(
        functionsResource(firebase, 'search-sessions', {
          type: 'question',
          companyID,
          environmentID,
          questionID,
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString(),
          search,
          offset
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodicity, startAt, endAt, environmentID, search, offset]);

  return {
    questionResource,
    setQuestionResource
  };
}
