import * as React from 'react';
import { routes, navigate } from '@churni/common';
import { LoginLayout } from './LoginLayout';
import { Box, Flex, Button, Text, Link, Input, Form } from '@churni/styleguide';
import { useAuth } from '@churni/fire';
import { SEO } from '../SEO';

/*
 * Callback view for the resetPassword firebase action.
 */
export function AuthResetPassword(props: {
  actionCode: string;
}): React.ReactElement {
  const { actionCode } = props;
  const auth = useAuth();
  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const timeout = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        await auth.verifyPasswordResetCode(actionCode);
        setVerified(true);
      } catch (err) {
        setError(err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  });

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError('Your passwords do not match. Check and try again.');
      return;
    }

    try {
      await auth.confirmPasswordReset(actionCode, newPassword);
      setSuccess(true);

      timeout.current = setTimeout(() => {
        navigate(routes.login());
      }, 5000);
    } catch (err) {
      setError(err.message);
    }
  };

  if (!verified && !error) {
    return null;
  }

  if (success) {
    return (
      <>
        <SEO title={'Reset password'} />
        <LoginLayout
          error={error}
          body={
            <Text>
              Your password has been successfully updated. You will now be asked
              to{' '}
              <Link variant="primary" to={routes.login()}>
                log-in
              </Link>{' '}
              with your new password.
            </Text>
          }
        />
      </>
    );
  }

  return (
    <>
      <SEO title={'Reset password'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Text variant="heading2">Reset password</Text>
              </Box>
            </Flex>

            <Form onSubmit={onSubmit}>
              <Box pb={2}>
                <Input
                  focus
                  type="password"
                  bg="white"
                  placeholder="Password"
                  value={newPassword}
                  onChangeValue={setNewPassword}
                />
              </Box>
              <Box pb={2}>
                <Input
                  bg="white"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmNewPassword}
                  onChangeValue={setConfirmNewPassword}
                />
              </Box>
              <Flex>
                <Button flex={1} type="submit" variant="primaryOutline">
                  <Text>Reset</Text>
                </Button>
              </Flex>
            </Form>
          </Box>
        }
      />
    </>
  );
}
