import * as React from 'react';
import { SearchContext } from './SearchContext';

import { Periodicity, Environment } from '../../containers';

export const useSearchParams = () => {
  const { search, filters, from, to, offset } = React.useContext(SearchContext);
  const { environmentID } = Environment.useContainer();

  const { periodicity } = Periodicity.useContainer();

  return {
    from,
    to,
    environmentID,
    offset,
    search,
    filters,
    periodicity
  };
};
