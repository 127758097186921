import { LoadingPage } from '@churni/common';
import { useAuth } from '@churni/fire';
import * as React from 'react';
import * as querystring from 'querystring';

export function LoginToken(props: any): React.ReactElement {
  const { location } = props;
  const { token } = querystring.parse(location.search.slice(1));

  const authInstance = useAuth();

  React.useEffect(() => {
    if (token) {
      authInstance.signInWithCustomToken(token);
    }
  }, [authInstance, token]);

  return <LoadingPage />;
}
