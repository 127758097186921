import * as React from 'react';
import { Box, Text } from '@churni/styleguide';

export function DashboardCard(props: {
  title: string;
  children: React.ReactElement;
}): React.ReactElement {
  const { title, info, children, sx, ...rest } = props;
  return (
    <Box sx={{ ...sx }} {...rest}>
      <Box>
        <Text variant={'overline'}>{title}</Text>
      </Box>
      <Box>
        <Text variant="body3" color="secondaryText">
          {info}
        </Text>
      </Box>

      <Box mt={3}>{children}</Box>
    </Box>
  );
}
