import * as React from 'react';
import { Box, Flex, Loading } from '@churni/styleguide';

export function LoadingPage(): React.ReactElement {
  return (
    <Flex
      css={{ minHeight: '100vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <Box sx={{ width: 50, height: 50 }}>
        <Loading bg={'#fafafa'} />
      </Box>
    </Flex>
  );
}
