import * as React from 'react';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/analytics';

import { FirebaseProvider } from '@churni/fire';
import { firebase } from '../config';

import { ApplicationRouter } from '../components';

export default function Application() {
  return (
    <FirebaseProvider config={firebase}>
      <ApplicationRouter />
    </FirebaseProvider>
  );
}
