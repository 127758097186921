import * as React from 'react';
import { Box, Flex, Button, Text } from '@churni/styleguide';
import { TooltipRenderProps } from 'react-joyride';

export function TooltipTour(props: TooltipRenderProps): React.ReactElement {
  const { index, step, skipProps, primaryProps, tooltipProps } = props;

  return (
    <Box
      {...tooltipProps}
      elevation={1}
      py={2}
      px={[1, 1, 1, 1, 3]}
      sx={{
        minWidth: 200,
        maxWidth: step.maxWidth || [330, 330, 330, 330, 400],
        textAlign: 'center'
      }}
    >
      <Box p={3}>
        <Text variant="subtitle3">{step.title}</Text>
        {step.content && <Box mt={1}>{step.content}</Box>}
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        sx={{ pt: 2, mt: 1, borderTop: 1, borderColor: 'border' }}
      >
        <Button variant="text" {...skipProps}>
          Skip
        </Button>
        <Button variant="primary" {...primaryProps}>
          {index < 9 ? <>Continue ({index + 1}/10)</> : <>Get started</>}
        </Button>
      </Flex>
    </Box>
  );
}
