import {
  Box,
  Container,
  Flex,
  Suspense,
  Button,
  Icon
} from '@churni/styleguide';
import * as React from 'react';
import { MobileHeader } from './MobileHeader';
import { Sidebar } from './Sidebar';
import { useCompany, Tour, NewCompany } from '../containers';
import { navigate, routes } from '@churni/common';
import Joyride from 'react-joyride';
import { TooltipTour } from './TooltipTour';
import { AppLoading } from './AppLoading';

export function DashboardLayout(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const { children } = props;

  const company = useCompany();
  const isDemo = company.id === 'demo';
  const { openPopup } = NewCompany.useContainer();

  const { tour, steps, stepIndex, onCallback } = Tour.useContainer();

  const skipDemo = async () => {
    navigate(routes.dashboard());
    openPopup();
  };

  return (
    <>
      {isDemo && (
        <TopBanner>
          You are currently viewing the Churni.io demo{' '}
          <Button
            className={'setup'}
            variant="secondary"
            sx={{
              border: 'white',
              bg: 'secondaryHover',
              mt: [1, 0],
              ml: 2,
              fontSize: 1,
              py: '4px'
            }}
            onClick={skipDemo}
          >
            Start setting up <Icon.ChevronRight />
          </Button>
        </TopBanner>
      )}
      {tour && (
        <Joyride
          disableScrolling={true}
          tooltipComponent={TooltipTour}
          steps={steps}
          continuous
          showProgress
          showSkipButton
          run
          stepIndex={stepIndex}
          callback={onCallback}
          disableBeacon={true}
          hideBackButton={true}
          disableOverlayClose={true}
        />
      )}
      <Box sx={{ display: ['block', 'block', 'block', 'none'] }}>
        <MobileHeader />
      </Box>

      <Container py={[1, 4]}>
        <Flex>
          <Box
            sx={{ minWidth: 180, display: ['none', 'none', 'none', 'block'] }}
          >
            <Sidebar />
          </Box>
          <Box ml={[0, 0, 0, 9]} flex={1}>
            <Suspense
              fallback={
                <AppLoading>
                  <Box mt={'76px'} />
                </AppLoading>
              }
            >
              {children}
            </Suspense>
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export function TopBanner(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const { children } = props;

  return (
    <Box
      sx={{
        top: 0,
        width: '100%',
        bg: 'primary',
        position: 'sticky',
        zIndex: 10,

        color: 'white'
      }}
    >
      <Container py={1} sx={{ textAlign: 'center' }}>
        <Flex>
          <Box width={[0, 3]}></Box>
          <Box ml={[0, 8]} mt={0} width={[12, 9]}>
            {children}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
