import * as React from 'react';
import { Box, Flex, Link, Icon } from '@churni/styleguide';

export function Breadcrumb(props: {
  breadcrumbElements: {
    title: string;
    url: string;
  }[];
}): React.ReactElement {
  const { breadcrumbElements } = props;
  return (
    <Flex as="ol" sx={{ listStyle: 'none', pl: 0 }}>
      {breadcrumbElements.map((element, i) => {
        const shouldDisplayChevron = i < breadcrumbElements.length - 1;

        return (
          <Box as="li">
            <Flex>
              <Link
                to={element.url}
                variant="primary"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: 250
                }}
                alt={element.title}
              >
                {element.title}
              </Link>
              {shouldDisplayChevron && (
                <Box mx={1}>
                  <Icon.ChevronRight />
                </Box>
              )}
            </Flex>
          </Box>
        );
      })}
    </Flex>
  );
}
