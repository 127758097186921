import { Periodicity } from '../../containers';
import * as React from 'react';
import { SearchContext } from './SearchContext';

import { useCustomersResource } from '../common';

export function useCustomersResourceFromContext() {
  const periodicityParams = Periodicity.useContainer();
  const { search, filters, from, to, offset } = React.useContext(SearchContext);

  return useCustomersResource({
    offset,
    periodicityParams,
    search,
    filters,
    from,
    to
  });
}
