import * as React from 'react';
import { Box, Text, Flex } from '@churni/styleguide';
import { useCompany } from '../containers';
import { useAuthUser } from '@churni/fire';

export function ProgressBar(props: {
  icon?: React.ReactElement;
  containerStyles?: object;
  textStyles?: object;
  barStyles?: object;
  textVariant?: string;
  text: string;
}): React.ReactElement {
  const { containerStyles, barStyles, icon, text = 'Progress' } = props;

  const company = useCompany();
  const user = useAuthUser();

  const steps = [
    true,
    company.onboarding.survey,
    company.onboarding.connect,
    company.onboarding.integrations,
    company.onboarding.invite
  ];

  if (user.providerData[0].providerId === 'password') {
    steps.push(user.emailVerified);
  }

  const stepsDone = steps.filter(step => [true, 'done', 'skip'].includes(step));
  const progress = Math.round((stepsDone.length / steps.length) * 100);

  return (
    <Box sx={{ ...containerStyles }}>
      <Flex alignItems="bottom">
        <Box>{icon}</Box>
        <Box ml={1}>
          <Text variant="subtitle3" sx={{ whiteSpace: 'nowrap' }}>
            {text}: {progress}%
          </Text>
        </Box>
      </Flex>
      <Box
        sx={{
          border: 1,
          height: 8,
          width: 100,
          borderColor: 'border',
          borderRadius: '12px',
          bg: 'inputBorder',
          ...barStyles
        }}
      >
        <Box
          sx={{
            bg: 'primary',
            height: '100%',
            width: `${progress}%`,
            borderRadius: '12px'
          }}
        />
      </Box>
    </Box>
  );
}
