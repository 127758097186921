import * as firebase from 'firebase';

export async function getDocument<T>(
  ref: firebase.firestore.DocumentReference
): Promise<T | null> {
  const snapshot = await ref.get();

  const document = snapshot.data();

  if (!document) {
    return null;
  }

  return document;
}

export async function getCollection<T>(
  ref: firebase.firestore.CollectionReference | firebase.firestore.Query
): Promise<T[]> {
  const data: object[] = [];

  const snapshot = await ref.get();

  snapshot.forEach(d => {
    const document = d.data();
    data.push(document);
  });

  return data;
}

export const setDocument = async (
  ref: firebase.firestore.DocumentReference,
  data: object
) => {
  return ref.set(data);
};
