import * as React from 'react';
import { useFirebase, functionsResource } from '@churni/fire';
import { useCompany, Environment, Periodicity } from '../../containers';

export function useDashboardResource() {
  const [startTransition] = React.useTransition({ timeoutMs: 5200 });
  const periodicityParams = Periodicity.useContainer();
  const firebase = useFirebase();
  const company = useCompany();

  const { environmentID: originalEnvironment } = Environment.useContainer();
  const { startAt, endAt, interval, periodicity } = periodicityParams;

  const environmentID = company.id === 'demo' ? 'demo' : originalEnvironment;
  const companyID = company.id;

  const [dashboardResource, setDashboardResource] = React.useState(
    functionsResource(firebase, 'search-sessions', {
      type: 'dashboard',
      companyID,
      environmentID,
      startAt: startAt.toISOString(),
      endAt: endAt.toISOString(),
      interval
    })
  );

  React.useEffect(() => {
    startTransition(() => {
      setDashboardResource(
        functionsResource(firebase, 'search-sessions', {
          type: 'dashboard',
          companyID,
          environmentID,
          startAt: startAt.toISOString(),
          endAt: endAt.toISOString(),
          interval: interval
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodicity, startAt, endAt, environmentID]);

  return {
    dashboardResource,
    setDashboardResource
  };
}
