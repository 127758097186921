import { firestore } from 'firebase';
import { genID } from '../helpers';
import { Form } from './Form';

export interface RevisionInterface {
  id: string;
  value: object[];
  variables: object;
}

export const Revision = {
  create(form: Partial<RevisionInterface> = {}): RevisionInterface {
    const defaultValues: RevisionInterface = {
      id: genID('rev'),
      value: [],
      variables: {}
    };

    return {
      ...defaultValues,
      ...form
    };
  },

  queryByID(db: firestore.Firestore) {
    return (companyID: string, formID: string, revisionID: string) => {
      return Form.queryByID(db)(companyID, formID)
        .collection('revisions')
        .doc(revisionID);
    };
  },

  createRevision(companyName: string) {
    return Revision.create({
      value: [
        {
          children: [{ children: [{ text: '' }], type: 'paragraph' }],
          type: 'header'
        }
      ]
    });
  }
};
