import * as React from 'react';
import { createContainer } from 'unstated-next';

function useCompany(initialState: boolean) {
  const [opened, setOpened] = React.useState(initialState);

  const openPopup = () => {
    setOpened(true);
  };

  const closePopup = () => {
    setOpened(false);
  };

  return {
    opened,
    openPopup,
    closePopup
  };
}

export const NewCompany = createContainer(useCompany);
