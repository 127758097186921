/* eslint-disable */
import * as React from 'react';
import { Box, useTheme, Flex, Text } from '@churni/styleguide';
import { Pie } from 'react-chartjs-2';

interface PieProps {
  labels: any;
  data: any;
  activeAnswer: number | null;
  onClick: (index: number) => void;
  withLabels?: boolean;
  labelTemplate?: string
  size?: number
}

export function PieChart(props: PieProps & { direction : 'column' | 'row' }): React.ReactElement {
  const { direction = 'column' } = props

  const [legend, setLegend] = React.useState();

  return (
    <Flex
      flexDirection={direction}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      <Chart setLegend={setLegend} {...props} />

      <Box flex={1} >
        {legend}
      </Box>
    </Flex>
  );
}

export function Chart(props: PieProps & { setLegend: (val: any) => void}) {
  const { labels, data, activeAnswer, onClick, withLabels = true, setLegend, labelTemplate = '2fr 1fr', size = 200 } = props;

  const theme = useTheme();
  const colors = theme.colors;
  const ref = React.useRef();


  React.useEffect(() => {
    if (ref.current) {
      setLegend(ref.current.chartInstance.generateLegend());
    }
  }, [data]);

  const dataset = {
    activeAnswer,
    labels,
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: [
          colors.pink,
          colors.teal,
          colors.blue,
          colors.yellow,
          colors.orange,
          colors.primary,
          colors.green,
          colors.cyan,
          colors.red,
          colors.purple
        ],
        borderColor: 'white',
        borderWidth: 3,
        hoverBorderColor: colors.border,
        data
      }
    ]
  };

  return <Box sx={{ minHeight: size, maxWidth: size }} pr={3}>
    <Pie
      activeAnswer={activeAnswer}
      ref={ref}
      onElementsClick={elements => {
        if (elements.length) {
          const index = elements[0]._index;

          if (onClick) {
            onClick(index);
          }
        }
      }}
      data={dataset}
      plugins={[
        {
          id: 'active',

          afterUpdate: (chart: any) => {
            const activeIndex = chart.config.data.activeAnswer;
            const dataset = chart.chart.data.datasets[0];

            if (dataset && dataset._meta) {
              const element = dataset._meta[chart.id].data[activeIndex];
              if (element) {


                element._model.outerRadius += 12.5;
                element._model.borderWidth = 1;
              }
            }
          }
        }
      ]}
      options={{


        layout: {
          padding: {
            top: 15,
            bottom: 15,
            right: 15,
            left: 15
          }
        },
        responsive: true,
        maintainAspectRatio: false,

        onHover: (event, chartElement) => {
          event.target.style.cursor = chartElement[0]
            ? 'pointer'
            : 'pointer';
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem: any, data: any) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const meta = dataset._meta[Object.keys(dataset._meta)[0]];
              const total = meta.total;
              const currentValue = dataset.data[tooltipItem.index];
              const percentage = parseFloat(
                ((currentValue / total) * 100).toFixed(1)
              );
              return currentValue + ' (' + percentage + '%)';
            },
            title: (tooltipItem: any, data: any) =>
              data.labels[tooltipItem[0].index]
          }
        },
        legendCallback: (chart: any) => {
          const data = chart.data;
          const datasets = data.datasets;

          const meta = datasets[0]._meta[Object.keys(datasets[0]._meta)[0]];
          const total = meta.total;

          return (
            <Box

              sx={{
                visibility: withLabels ? 'visible' : 'hidden',
                // overflowY: 'auto'
              }}
            >
              {datasets.length &&
              datasets[0].data.map((currentValue, i) => {
                const percentage = parseFloat(
                  ((currentValue / total) * 100).toFixed(1)
                );

                const isActive = i === activeAnswer;

                return (
                  <Box
                    key={i}
                    alignItems={'center'}
                    my={'4px'}
                    sx={{
                      cursor: 'pointer',
                      display: 'grid',
                      gridTemplateColumns: labelTemplate
                    }}
                    onClick={() => {
                      if (onClick) {
                        onClick(i);
                      }
                    }}
                  >
                    <Flex alignItems="center">
                      <Box
                        sx={{
                          borderRadius: 'radius',
                          minWidth: 16,
                          minHeight: 16,
                          width: 16,
                          height: 16,
                          bg: datasets[0].backgroundColor[i]
                        }}
                      />
                      <Box mx={1} sx={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <Text variant={'body2'} >
                          {labels[i]}
                        </Text>
                      </Box>
                    </Flex>
                    <Flex justifyContent={'space-between'} sx={{color: datasets[0].backgroundColor[i]}}>
                      <Box><Text variant={'body2'} sx={{fontWeight: 2}}>{currentValue}</Text></Box>
                      <Box ml={1}><Text variant={'body2'} sx={{fontWeight: 2}}>{percentage}%</Text></Box>
                    </Flex>
                  </Box>
                );
              })}
            </Box>
          );
        }
      }}
      legend={{ display: false }}
    />
  </Box>
}
