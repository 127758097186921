import * as React from 'react';
import {
  Box,
  Flex,
  DataTable,
  Link,
  useDebounce,
  Icon,
  Text
} from '@churni/styleguide';
import { routes, navigate } from '@churni/common';
import * as querystring from 'querystring';

import { Filters } from './Filters';

import { ActiveFilters } from './ActiveFilters';
import { Periodicity, Environment } from '../../containers';
import { SearchContext } from './SearchContext';
import { useSearchParams } from './useSearchParams';
import { useCustomersResourceFromContext } from './useCustomersResourceFromContext';
import { Pagination } from '../common/Pagination';
import { Searchbar } from '../common/Searchbar';

export function Customers(props: any): React.ReactElement {
  const qs = querystring.parse(props.location.search.slice(1));
  const JSONFilters = qs.filters ? JSON.parse(qs.filters) : {};
  const search = qs.search;
  const periodicity = qs.periodicity;
  const from = qs.from;
  const to = qs.to;
  const offset = qs.offset ? parseInt(qs.offset) : 0;

  const { periodicity: parentPeriodicity } = Periodicity.useContainer();
  const { environmentID } = Environment.useContainer();

  React.useEffect(
    () => {
      if (parentPeriodicity !== periodicity && !from && !to) {
        navigate(
          routes.customers({
            from,
            to,
            filters: JSONFilters,
            search,
            periodicity: parentPeriodicity,
            environmentID
          }),
          { replace: true }
        );
      }
    }, // eslint-disable-next-line
    [periodicity, parentPeriodicity]);
  React.useEffect(
    () => {
      if (environmentID !== qs.environmentID) {
        navigate(
          routes.customers({
            from,
            to,
            filters: JSONFilters,
            search,
            periodicity: parentPeriodicity,
            environmentID
          }),
          { replace: true }
        );
      }
    }, // eslint-disable-next-line
    [environmentID]);

  return (
    <>
      <SearchContext.Provider
        value={{
          search,
          from,
          to,
          filters: JSONFilters,
          offset
        }}
      >
        <CustomersContent />
      </SearchContext.Provider>
    </>
  );
}

export function CustomersContent(): React.ReactElement {
  const {
    periodicity,
    search: qsSearch,
    filters,
    from,
    to
  } = useSearchParams();

  const [startTransition] = React.useTransition({ timeoutMs: 5200 });
  const [search, setSearch] = React.useState(qsSearch);

  const debouncedSearch = useDebounce(search, 300);

  React.useEffect(
    () => {
      if (qsSearch !== search) {
        startTransition(() =>
          navigate(
            routes.customers({
              from,
              to,
              filters,
              periodicity,
              search: debouncedSearch
            })
          )
        );
      }
    }, // eslint-disable-next-line
    [JSON.stringify(filters), debouncedSearch]);

  return (
    <Box sx={{ position: 'relative' }}>
      {(from || to) && <FixedDates from={from} to={to} />}

      <Flex sx={{ position: 'relative' }}>
        <Box width={4} sx={{ display: ['none', 'none', 'block'] }}>
          <Searchbar
            mb={2}
            value={search}
            setValue={setSearch}
            placeholder={'Search...'}
          />

          <Filters />
        </Box>
        <Box pl={[0, 0, 5]} width={'100%'}>
          <ActiveFilters />
          <CustomersTable />
        </Box>
      </Flex>
    </Box>
  );
}

export const CustomersTable = () => {
  const { customersResource } = useCustomersResourceFromContext();
  const { data } = customersResource.read();
  const { offset, ...params } = useSearchParams();

  const rows = data.customers.map(cus => [
    <Link variant="primary" to={routes.customer(cus.customer.id)}>
      <Text variant={'subtitle4'}>{cus.customer.name}</Text>
    </Link>,
    new Date(cus.createdAt).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short'
    }),
    <Text
      color={
        { canceled: '#FF5A6E', pending: '#FAB32A', saved: '#32D8D1' }[
          cus.status
        ]
      }
    >
      {cus.status}
    </Text>,
    `${cus.customer.subscriptionLength} month${
      cus.customer.subscriptionLength > 1 ? 's' : ''
    }`
  ]);

  return (
    <Box>
      <DataTable
        className={'customers_table'}
        columns={['Name', 'Date', 'Status', 'Sub. Length']}
        rows={rows}
        gridTemplateColumns={'1.5fr 1fr  1fr 1fr '}
        styles={{
          body: { maxHeight: 'calc(100vh - 400px)', overflow: 'auto' },
          header: {}
        }}
        footer={
          <Flex alignItems={'center'} justifyContent={'space-between'}>
            <Flex alignItems={'center'} color={'secondaryText'}>
              <Icon.Users mr={1} />
              <Text variant={'subtitle3'}>{data.count} customers</Text>
            </Flex>
            {data.customers.length < data.count && (
              <Pagination
                nbPages={Math.ceil(data.count / 20)}
                page={Math.ceil(offset / 20) + 1}
                onPrev={() => {
                  navigate(
                    routes.customers({
                      offset: offset - 20,
                      ...params
                    })
                  );
                }}
                onNext={() => {
                  navigate(
                    routes.customers({
                      offset: offset + 20,
                      ...params
                    })
                  );
                }}
              />
            )}
          </Flex>
        }
      />
    </Box>
  );
};

export const FixedDates = (props: { from; to }) => {
  const { from, to } = props;
  const { search, filters, periodicity } = useSearchParams();

  const displayFrom = from
    ? new Date(from).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short'
      })
    : null;
  const displayTo = to
    ? new Date(to).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short'
      })
    : null;

  return (
    <Flex alignItems={'center'}>
      <Box mr={1}>
        {displayFrom} - {displayTo}
      </Box>
      <Link to={routes.customers({ search, filters, periodicity })}>
        <Icon.X />
      </Link>
    </Flex>
  );
};
