import { Box, Text, Icon, Link, Flex, Button } from '@churni/styleguide';
import * as React from 'react';
import { routes, Logo, useLocation } from '@churni/common';

import { ProgressBar } from '../Onboarding/ProgressBar';

import { useCompany, Upgrade } from '../containers';
import { getTabs } from './getTabs';

export function Sidebar(): React.ReactElement {
  const company = useCompany();
  const { location } = useLocation();

  const { openPopup } = Upgrade.useContainer();

  const trialEndIn = company.billing.trialEnd
    ? Math.round(
        (company.billing.trialEnd.toDate() - new Date()) / (24 * 60 * 60 * 1000)
      )
    : null;

  const pathname = location.pathname;

  const dashboardTabs = getTabs(pathname);

  return (
    <Flex
      flexDirection={'column'}
      sx={{
        position: 'sticky',
        top: 32
      }}
    >
      <Flex alignItems={'center'} mb={4}>
        <Link to={routes.app()}>
          <Logo className={'sidebar-logo'} />
        </Link>
      </Flex>
      <Flex
        flexDirection={'column'}
        justifyContent="space-between"
        sx={{ height: '80vh' }}
      >
        <Box>
          {Object.values(dashboardTabs).map(dashboardTab => (
            <SidebarLink
              key={dashboardTab.title}
              title={dashboardTab.title}
              to={dashboardTab.to}
              isActive={dashboardTab.isActive}
              icon={dashboardTab.icon}
            />
          ))}
        </Box>

        <Box>
          <Box mt={5}>
            {company.id !== 'demo' && !company.onboarding.completed && (
              <Box py={1}>
                <Link to={routes.onboarding()}>
                  <Flex
                    alignItems="center"
                    sx={{
                      color: pathname.match(/^\/getting-started/)
                        ? 'primary'
                        : 'text'
                    }}
                  >
                    <ProgressBar
                      icon={<Icon.Percent />}
                      text={'Getting Started'}
                      textStyles={{ color: 'inherit' }}
                      textVariant={'subtitle3'}
                      barStyles={{
                        width: 170,
                        mt: '4px'
                      }}
                    />
                  </Flex>
                </Link>
              </Box>
            )}
          </Box>

          <SidebarLink
            key={'documentation'}
            title={'Docs'}
            to={'https://www.churni.io/docs/'}
            icon={<Icon.BookOpen />}
            linkType="external"
          />

          <Box p={1} ml={-1}>
            <Link
              as={'span'}
              sx={{ cursor: 'pointer' }}
              className={'support'}
              onClick={() => {
                window.$crisp.push(['do', 'chat:open']);
              }}
            >
              <Text variant={'subtitle3'}>
                <Flex>
                  <Box>
                    <Icon.MessageSquare />
                  </Box>
                  <Box ml={1}>
                    <Text
                      sx={{ verticalAlign: 'middle', whiteSpace: 'nowrap' }}
                    >
                      Support / Feedback
                    </Text>
                  </Box>
                </Flex>
              </Text>
            </Link>
          </Box>

          {trialEndIn > 0 && company.billing.subscriptionID === null && (
            <Box p={1} ml={-1}>
              <Link
                as={'span'}
                onClick={openPopup}
                className={'sidebar-upgrade'}
              >
                <Button variant="secondary" icon={<Icon.Unlock />}>
                  <Text sx={{ verticalAlign: 'middle' }}>
                    Trial : {trialEndIn} days left
                  </Text>
                </Button>
              </Link>
            </Box>
          )}
          {trialEndIn < 0 && company.billing.subscriptionID === null && (
            <Box p={1} ml={-1}>
              <Link
                as={'span'}
                onClick={openPopup}
                className={'sidebar-upgrade'}
              >
                <Button variant="secondary" icon={<Icon.Unlock />}>
                  <Text sx={{ verticalAlign: 'middle' }}>Upgrade account</Text>
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}

export function SidebarLink(props: {
  title: string;
  to: string;
  isActive: boolean;
  icon: React.ReactElement;
  linkType?: 'internal' | 'external';
}): React.ReactElement {
  const { title, to, isActive, icon, linkType = 'internal' } = props;

  const linkProps =
    linkType === 'internal'
      ? {
          to
        }
      : {
          as: 'a',
          href: to
        };

  return (
    <Box key={title}>
      <Box
        sx={{ bg: 'background', display: 'inline-block' }}
        p={1}
        ml={-1}
        className={title}
      >
        <Link {...linkProps}>
          <Flex
            alignItems={'center'}
            sx={{ color: isActive ? 'primary' : 'text' }}
          >
            <Box>{icon}</Box>
            <Box ml={1}>
              <Text variant={'subtitle3'}>
                <Text sx={{ verticalAlign: 'middle' }}>{title}</Text>
              </Text>
            </Box>
          </Flex>
        </Link>
      </Box>
    </Box>
  );
}
