import * as React from 'react';
import { Popup, Box, Modal, Button, Text } from '@churni/styleguide';
import { Tour, NewCompany } from '../containers';

export function WelcomeModal(props: {
  onClose: () => void;
}): React.ReactElement {
  const { onClose } = props;
  const { startTour } = Tour.useContainer();
  const { openPopup } = NewCompany.useContainer();

  const startDemo = () => {
    onClose();
    startTour();
  };

  const onSkip = () => {
    onClose();
    openPopup();
  };

  return (
    <Popup>
      <Box sx={{ maxWidth: 600 }}>
        <Modal>
          <Text variant={'heading1'}>
            <span role="img" aria-label="Welcome">
              👋
            </span>{' '}
            Welcome!
          </Text>

          <Modal.Body pb={1}>
            {/* Mobile */}
            <Box sx={{ display: ['block', 'none', 'none'] }}>
              <Box mb={4}>
                <Text variant={'subtitle3'}>
                  {`Thank you for joining Churni. Let's start!`}
                </Text>
                <Box mt={2}>
                  <Text color={'secondaryText'} variant={'body2'}>
                    We recommend using this application on a computer
                  </Text>
                </Box>
              </Box>
              <Box
                justifyContent="flex-end"
                sx={{
                  borderTop: 1,
                  borderColor: 'border',
                  pt: 3,
                  mt: 1,
                  display: ['flex', 'none', 'none']
                }}
              >
                <Button onClick={onSkip} className={'welcome-start-mobile'}>
                  Continue
                </Button>
              </Box>
            </Box>

            {/* Desktop */}
            <Box sx={{ display: ['none', 'block', 'block'] }}>
              <Box mb={4}>
                <Text variant={'subtitle3'}>
                  {`Let's have a quick product tour.`}
                </Text>
              </Box>
              <Box
                justifyContent="space-between"
                sx={{
                  borderTop: 1,
                  borderColor: 'border',
                  pt: 3,
                  mt: 1,
                  display: ['none', 'flex', 'flex']
                }}
              >
                <Button
                  variant="text"
                  className="welcome-skip"
                  onClick={onSkip}
                >
                  Skip the demo
                </Button>
                <Box ml={1}>
                  <Button onClick={startDemo} className={'welcome-start-demo'}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal.Body>
        </Modal>
      </Box>
    </Popup>
  );
}
