import * as React from 'react';
import { SavePerformanceCard } from './SavePerformanceCard';
import { SessionsStatsCard } from './SessionsStatsCard';
import { SaveReasonsCard } from './SaveReasonsCard';
import { useDashboardResource } from '../common';
import { Periodicity } from '../../containers';
import { Grid } from '@churni/styleguide';

export function Overview(props: {}) {
  const { dashboardResource } = useDashboardResource();
  const { interval } = Periodicity.useContainer();

  return (
    <Grid columns={[1, 1, 3]} columnGap={6} rowGap={6}>
      <SavePerformanceCard resource={dashboardResource} interval={interval} />
      <SessionsStatsCard resource={dashboardResource} />
      <SaveReasonsCard resource={dashboardResource} />
    </Grid>
  );
}
