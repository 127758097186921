import * as React from 'react';
import { useFirebase, functionsResource } from '@churni/fire';
import { useCompany, Environment } from '../../containers';

export function useCustomerResource(props: { customerID: string }) {
  const { customerID } = props;

  const firebase = useFirebase();
  const company = useCompany();

  const { environmentID: originalEnvironment } = Environment.useContainer();

  const environmentID = company.id === 'demo' ? 'demo' : originalEnvironment;
  const companyID = company.id;

  const [customerResource, setCustomerResource] = React.useState(
    functionsResource(firebase, 'search-sessions', {
      type: 'customer',
      companyID,
      environmentID,
      customerID
    })
  );

  React.useEffect(() => {
    setCustomerResource(
      functionsResource(firebase, 'search-sessions', {
        type: 'customer',
        companyID,
        environmentID,
        customerID
      })
    );
  }, [companyID, customerID, environmentID, firebase]);

  return {
    customerResource,
    setCustomerResource
  };
}
