import * as React from 'react';
import { CompanyInterface } from '@churni/core';

export const CompanyContext = React.createContext<CompanyInterface | null>(
  null
);

export const useCompany = (): CompanyInterface => {
  return React.useContext(CompanyContext);
};
