import * as React from 'react';
import { createContainer } from 'unstated-next';
import { Form, Company } from '@churni/core';
import { useDatabase } from '@churni/fire';
import { useCompany } from './Company';

function useFormState(initialState: any) {
  const db = useDatabase();
  const company = useCompany();
  const [hasChanged, setChanged] = React.useState(false);
  const [editValue, setOriginalValue] = React.useState(initialState);
  const [answerValue, setAnswerValue] = React.useState(initialState);
  const [mode, setMode] = React.useState('edit');

  const setEditValue = (newValue: object[]) => {
    if (editValue !== newValue) {
      setOriginalValue(newValue);
      setChanged(true);
    }
  };

  const reset = (newValue: object[]) => {
    setOriginalValue(newValue);
    setChanged(false);
  };

  const save = async (props: { formID: string }) => {
    const { formID } = props;
    const savedValue = JSON.parse(JSON.stringify(editValue));

    setChanged(false);
    await Form.updateFormContent(db)(company.id, formID, savedValue);

    if (['todo', 'skip'].includes(company.onboarding.survey)) {
      await Company.queryByID(db)(company.id).update({
        onboarding: {
          ...company.onboarding,
          survey: 'done'
        }
      });
    }
  };

  return {
    reset,
    save,
    mode,
    setMode,
    editValue,
    setEditValue,
    answerValue,
    setAnswerValue,
    hasChanged,
    setChanged
  };
}

export const FormState = createContainer(useFormState);
