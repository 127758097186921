import { firestore } from 'firebase';
import { genID } from '../helpers';
import { Company } from './Company';

export interface EnvironmentInterface {
  id: string;
  appID: string;
  companyID: string;
  defaultFormID: string;
}

export const Environment = {
  create(
    environment: Partial<EnvironmentInterface> = {}
  ): EnvironmentInterface {
    const defaultValues = {
      id: 'production',
      appID: genID('app'),
      companyID: '',
      defaultFormID: ''
    };

    return {
      ...defaultValues,
      ...environment
    };
  },
  queryByCompany(db: firestore.Firestore) {
    return (companyID: string) =>
      Company.queryByID(db)(companyID).collection('environments');
  },
  queryByID(db: firestore.Firestore) {
    return (companyID: string, environmentID: string) =>
      Company.queryByID(db)(companyID)
        .collection('environments')
        .doc(environmentID);
  },
  queryByAppID(db: firestore.Firestore) {
    return (appID: string) =>
      db.collectionGroup('environments').where('appID', '==', appID);
  }
};
