import * as React from 'react';
import { Box, useColors } from '@churni/styleguide';

import { Line } from 'react-chartjs-2';

export function LineChart(props: {
  labels: any;
  data: any;
  onClick: (index: number) => void;
}): React.ReactElement {
  const { labels, data, onClick } = props;

  const colors = useColors();

  const dataset = {
    labels,
    datasets: [
      {
        backgroundColor: colors.primary,
        borderColor: colors.border,
        borderWidth: 1,
        hoverBorderColor: colors.border,
        data
      }
    ]
  };

  return (
    <Box>
      <Line
        onElementsClick={elements => {
          if (elements.length) {
            const index = elements[0]._index;
            onClick(index);
          }
        }}
        data={dataset}
        options={{
          scales: {
            yAxes: [
              {
                display: false
              }
            ],
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          },

          onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'pointer';
          },
          maintainAspectRatio: false
        }}
        legend={{
          display: false,
          position: 'bottom',
          fullWidth: true
        }}
      />
    </Box>
  );
}
