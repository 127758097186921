import { auth as firebaseAuth, firestore } from 'firebase';
import { genID } from '../helpers';

export interface UserInterface {
  id: string;
  displayName: string;
  photoURL: string;
}

export const User = {
  create(form: Partial<UserInterface> = {}): UserInterface {
    const defaultValues = {
      id: genID('user'),
      displayName: '',
      photoURL: ''
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByID(db: firestore.Firestore) {
    return (userID: string) => {
      return db.collection('users').doc(userID);
    };
  },

  /*
   * Reauthenticate user (required to change password/email)
   */
  reauthenticate(auth: firebaseAuth.Auth) {
    return (credential: firebaseAuth.AuthCredential) => {
      const user = auth.currentUser;
      return user.reauthenticateWithCredential(credential);
    };
  },

  /*
   * Update email
   */
  updateEmail(auth: firebaseAuth.Auth) {
    return async (newEmail: string) => {
      const user = auth.currentUser;
      await user.updateEmail(newEmail);
    };
  },

  /*
   * Update email
   */
  updatePassword(auth: firebaseAuth.Auth) {
    return async (newPassword: string) => {
      const user = auth.currentUser;
      await user.updatePassword(newPassword);
    };
  },

  /*
   * Sign-in with email.
   */
  loginWithEmail(auth: firebaseAuth.Auth) {
    return async (email: string, password: string) => {
      await auth.signInWithEmailAndPassword(email, password);
    };
  },

  /*
   * Logout
   */
  logout(auth: firebaseAuth.Auth) {
    return async () => {
      await auth.signOut();
    };
  },

  /*
   * Reset password by email
   */
  resetPassword(auth: firebaseAuth.Auth) {
    return async (email: string) => {
      await auth.sendPasswordResetEmail(email);
    };
  },

  /*
   * Sign-in with email.
   */
  signupWithEmail(auth: firebaseAuth.Auth) {
    return async (email: string, password: string) => {
      await auth.createUserWithEmailAndPassword(email, password);
    };
  },

  /*
   * Sign-in with google.
   */
  loginWithProvider(auth: firebaseAuth.Auth) {
    return async (provider: firebaseAuth.AuthProvider) => {
      await auth.signInWithRedirect(provider);
    };
  }
};
