import * as React from 'react';
import {
  Box,
  Text,
  Suspense,
  Link,
  useDebounce,
  Flex,
  Icon
} from '@churni/styleguide';
import { useQuestionResource } from '../common';
import { routes } from '@churni/common';
import { Insights } from '../../containers';
import { Searchbar } from '../common/Searchbar';
import { Pagination } from '../common/Pagination';

export function TextQuestionCard(props: {
  question: any;
  periodicityParams: any;
}): React.ReactElement {
  const { question, periodicityParams, ...rest } = props;
  const [startTransition] = React.useTransition({ timeoutMs: 5200 });

  // Preserve searches accross tab changes
  const {
    getSearch: getContainerSearch,
    setSearch: setContainerSearch
  } = Insights.useContainer();

  const search = getContainerSearch(question.key) || '';
  const setSearch = val => setContainerSearch(question.key, val);

  const [offset, setOffset] = React.useState(0);

  const debouncedSearch = useDebounce(search, 200);

  React.useEffect(
    () => {
      startTransition(() => {
        setContainerSearch(question.key, debouncedSearch);
        setOffset(0);
      });
    }, //eslint-disable-next-line
    [debouncedSearch, question.key]);

  const { questionResource } = useQuestionResource({
    questionID: question.key,
    periodicityParams,
    search: debouncedSearch,
    offset
  });

  return (
    <Flex
      flexDirection={'column'}
      key={question.key}
      sx={{ minHeight: 400, maxHeight: 600 }}
      elevation={2}
      {...rest}
    >
      {/* Allow title to be 2 lines long, hide after */}
      <Box p={3}>
        <Text variant={'heading1'}>{question.infos.title} </Text>
      </Box>
      <Box mx={3} sx={{ borderBottom: 1, borderColor: 'border', pb: 2 }}>
        <Searchbar
          value={search}
          setValue={setSearch}
          placeholder={'Search...'}
        />
      </Box>

      <Suspense>
        <TextQuestionContent
          offset={offset}
          setOffset={setOffset}
          resource={questionResource}
          questionID={question.key}
          search={search}
        />
      </Suspense>
    </Flex>
  );
}

function TextQuestionContent(props: {
  resource: any;
  questionID: string;
  search: string;
  offset: number;
  setOffset: number;
}): React.ReactElement {
  const { resource, questionID, search, offset, setOffset } = props;

  const {
    data: { sessions, total }
  } = resource.read();

  const ref = React.useRef();

  return (
    <>
      <Box ref={ref} sx={{ overflow: 'auto' }} flex={1}>
        {sessions.length === 0 && (
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            sx={{ height: '100%', p: 5 }}
          >
            No results
          </Flex>
        )}
        {sessions.map(({ answer, customer, highlight }, index) => {
          const qAnswer = highlight || answer;

          return (
            <Flex
              alignItems={'center'}
              key={customer.id}
              flexWrap={['wrap', 'wrap', 'nowrap']}
              sx={{
                py: 2,
                mx: 3,
                borderBottom: index < sessions.length - 1 ? 1 : 0,
                borderColor: 'border'
              }}
            >
              <Box
                sx={{
                  minWidth: 250,
                  maxWidth: 250,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                <Link variant={'primary'} to={routes.customer(customer.id)}>
                  <Text variant={'subtitle3'}>{customer.name}</Text>
                </Link>
              </Box>
              <Box>
                <Text
                  sx={{
                    em: {
                      fontStyle: 'normal',
                      bg: '#fff2ac'
                    }
                  }}
                  variant="body1"
                  dangerouslySetInnerHTML={{ __html: `"${qAnswer}"` }}
                />
              </Box>
            </Flex>
          );
        })}
      </Box>
      <Flex
        mx={3}
        py={3}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ borderTop: 1, borderColor: 'border' }}
      >
        <Link
          mr={1}
          variant={'primary'}
          to={
            search
              ? routes.customers({
                  filters: { questions: [{ key: questionID, search }] }
                })
              : routes.customers({
                  filters: {
                    questions: [{ key: questionID, search: '*' }]
                  }
                })
          }
        >
          <Flex alignItems={'center'}>
            <Icon.Users mr={1} />
            <Text variant={'subtitle3'}>{total} customers</Text>
          </Flex>
        </Link>
        <Box>
          {sessions.length < total && (
            <Pagination
              nbPages={Math.ceil(total / 20)}
              page={Math.ceil(offset / 20) + 1}
              onPrev={() => {
                ref.current.scrollTo({ top: 0 });
                setOffset(offset - 20);
              }}
              onNext={() => {
                ref.current.scrollTo({ top: 0 });
                setOffset(offset + 20);
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  );
}
