import * as React from 'react';
import { Flex, Icon, Text } from '@churni/styleguide';

export function Pagination(props: {
  onPrev: () => void;
  onNext: () => void;
  nbPages: number;
  page: number;
}) {
  const { onPrev, onNext, nbPages, page } = props;

  const canClickPrev = page > 1;
  const canClickNext = page < nbPages;

  return (
    <Flex sx={{ border: 1, borderColor: 'border', borderRadius: 'circle' }}>
      <Flex
        onClick={canClickPrev ? onPrev : null}
        sx={{
          width: 28,
          height: 28,
          borderRight: 1,
          borderColor: 'border',
          cursor: canClickPrev ? 'pointer' : 'initial'
        }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Icon.ChevronLeft />
      </Flex>
      <Flex
        sx={{ height: 28 }}
        alignItems={'center'}
        justifyContent={'center'}
        px={2}
      >
        <Text variant={'overline'} sx={{ whiteSpace: 'nowrap' }}>
          {page} of {nbPages}
        </Text>
      </Flex>
      <Flex
        onClick={canClickNext ? onNext : null}
        sx={{
          width: 28,
          height: 28,
          borderLeft: 1,
          borderColor: 'border',
          cursor: canClickNext ? 'pointer' : 'initial'
        }}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Icon.ChevronRight />
      </Flex>
    </Flex>
  );
}
