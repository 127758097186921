import * as React from 'react';
import { Flex, Input, Icon } from '@churni/styleguide';

export function Searchbar(props: {
  value: any;
  setValue: (val: any) => void;
  placeholder: string;
}) {
  const { value, setValue, placeholder, ...rest } = props;

  return (
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'}
      sx={{
        border: 1,
        borderColor: 'border',
        borderRadius: 'circle',
        px: 1
      }}
      {...rest}
    >
      <Icon.Search />
      <Input
        ml={1}
        pl={0}
        variant="passive"
        value={value}
        onChangeValue={setValue}
        placeholder={placeholder}
      />
    </Flex>
  );
}
