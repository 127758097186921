import { firestore } from 'firebase';
import * as fileType from 'file-type';
import { genID } from '../helpers';
import { Member } from './Member';
import { Environment } from './Environment';
import { Revision } from './Revision';
import { Form } from './Form';
import { Invite } from './Invite';

export interface CompanyInterface {
  id: string;
  title: string;
  ownerID: string;
  createdAt: firebase.firestore.Timestamp;
  onboarding: {
    completed: boolean;
    survey: 'done' | 'skip' | 'todo';
    connect: 'done' | 'skip' | 'todo';
    integrations: 'done' | 'skip' | 'todo';
    invite: 'done' | 'skip' | 'todo';
  };
  setup: {
    testing: boolean;
    production: boolean;
  };

  billing: {
    trialEnd: firebase.firestore.Timestamp | null;
    cancellationDate: firebase.firestore.Timestamp | null;
    customerID: string | null;
    subscriptionID: string | null;
    productID: string | null;
    planID: string | null;
    periodicity: string | null;
    quantity: number | null;
    email: string | null;
    nextBillDate: string | null;
    cancelURL: string | null;
    updateURL: string | null;
    type: 'paddle';
  };
}

export const Company = {
  create(form: Partial<CompanyInterface> = {}): CompanyInterface {
    const defaultValues = {
      id: genID('comp'),
      title: '',
      ownerID: '',
      setup: {
        testing: false,
        production: false
      },
      onboarding: {
        completed: false,
        survey: 'todo',
        connect: 'todo',
        integrations: 'todo',
        invite: 'todo'
      },
      billing: {
        trialEnd: null,
        customerID: null,
        subscriptionID: null,
        productID: null,
        planID: null,
        quantity: null,
        periodicity: null,
        price: null,
        email: null,
        nextBillDate: null,
        cancelURL: null,
        updateURL: null,
        type: 'paddle'
      }
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByCompany(db: firestore.Firestore) {
    return (companyID: string) => {
      return db.collection('companies').where('companyID', '==', companyID);
    };
  },
  queryByID(db: firestore.Firestore) {
    return (companyID: string) => {
      return db.collection('companies').doc(companyID);
    };
  },

  /*
   * Create a company
   */
  createCompany(db: firestore.Firestore) {
    return async (
      company: CompanyInterface,
      ownerEmail: string
    ): Promise<CompanyInterface> => {
      const batch = db.batch();

      const member = Member.create({
        id: company.ownerID,
        role: 'admin',
        companyID: company.id,
        email: ownerEmail
      });

      const testingEnvironment = Environment.create({
        id: 'testing',
        companyID: company.id
      });
      const prodEnvironment = Environment.create({
        id: 'production',
        companyID: company.id
      });

      const testingRevision = Revision.createRevision(company.title, 'testing');

      const form = Form.create({
        title: 'Untitled',
        companyID: company.id,

        primaryRevision: testingRevision.id
      });

      testingEnvironment.defaultFormID = form.id;
      prodEnvironment.defaultFormID = form.id;

      batch.set(Company.queryByID(db)(company.id), company);
      batch.set(
        Environment.queryByID(db)(company.id, testingEnvironment.id),
        testingEnvironment
      );
      batch.set(
        Environment.queryByID(db)(company.id, prodEnvironment.id),
        prodEnvironment
      );
      batch.set(Member.queryByID(db)(company.id, member.id), member);

      // // Create form / revision
      batch.set(Form.queryByID(db)(form.companyID, form.id), form);
      batch.set(
        Revision.queryByID(db)(form.companyID, form.id, testingRevision.id),
        testingRevision
      );

      await batch.commit();

      await Invite.generateInvite(db)(company.id);

      return company;
    };
  },

  uploadCompanyLogo(storage: firebase.storage.Storage) {
    return async (company: CompanyInterface, logo: Blob): Promise<void> => {
      const { ext, mime } = fileType(await logo.arrayBuffer());
      const path = `companies/${company.id}/logo.${ext}`;
      const ref = storage.ref(path);

      const metadata = {
        contentType: mime
      };

      const { task } = await ref.put(logo, metadata);

      return task.snapshot.ref.getDownloadURL();
    };
  },

  /*
   * Update company title
   */
  updateCompanyTitle(db: firestore.Firestore) {
    return async (workspaceID: string, title: string): Promise<void> => {
      await Company.queryByID(db)(workspaceID).update({ title });
    };
  }
};
