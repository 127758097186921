/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { useCompany } from '../containers';
import { Environment, genID } from '@churni/core';
import { Text, Icon, Box, Tooltip } from '@churni/styleguide';
import { urls } from '../../config';
import { useDocument, useDatabase } from '@churni/fire';
import { routes } from '@churni/common';

export function PreviewForm(): React.ReactElement {
  const db = useDatabase();
  const company = useCompany();
  const isDemo = company.id === 'demo';
  const [loading, setLoading] = React.useState(false);

  const testingEnv = useDocument(
    Environment.queryByID(db)(company.id, 'testing')
  );

  const onClick = async () => {
    let url;

    if (isDemo) {
      url = `${urls.website}/s/demo`;
    } else {
      setLoading(true);

      const res = await fetch(`${urls.app}/sessions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          appID: testingEnv.appID,
          cancelConfirmationURL: routes.toAppURL(routes.flow()),
          saveReturnURL: routes.toAppURL(routes.flow()),
          customer: {
            id: genID('customer'),
            email: 'test@churni.io',
            name: 'Test (Preview form)',
            domain: 'churni.io',
            createdAt: new Date('2019-01-01').getTime()
          },
          preview: true,
          variables: {
            test: 'test'
          }
        })
      });
      const data = await res.json();

      url = data.url;
      setLoading(false);
    }

    window.open(url, '__blank');
  };

  return (
    <Box
      sx={{ bg: 'background', display: 'inline-block' }}
      className={'preview'}
    >
      <Tooltip content={'Preview cancel flow as a customer would see it'}>
        <Text
          className={'preview-form'}
          variant={'subtitle3'}
          sx={{ cursor: 'pointer' }}
          onClick={onClick}
          icon={
            loading ? <Icon.Loading color={'primary'} /> : <Icon.ExternalLink />
          }
        >
          Preview
        </Text>
      </Tooltip>
    </Box>
  );
}
