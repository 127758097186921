import * as React from 'react';
import { addDays } from '@churni/core';
import { PeriodicityParams } from '../common';
import { createContainer } from 'unstated-next';
import * as querystring from 'querystring';
import { useLocation } from '../../../../common/src';

export function usePeriodicity(): PeriodicityParams {
  const { location } = useLocation();
  const qs = querystring.parse(location.search.slice(1));
  const initialPeriodicity = qs.periodicity || 'month';

  const [periodicity, setPeriodicity] = React.useState(initialPeriodicity);

  const [endAt] = React.useState(new Date(new Date().setUTCHours(0, 0, 0, 0)));

  const getParams = periodicity => {
    if (periodicity === 'week') {
      return { startAt: addDays(endAt, -7), interval: 'day' };
    } else if (periodicity === 'month') {
      return { startAt: addDays(endAt, -30), interval: 'week' };
    } else if (periodicity === 'quarter') {
      return { startAt: addDays(endAt, -90), interval: 'week' };
    } else {
      return { startAt: addDays(endAt, -365), interval: 'month' };
    }
  };

  const intitialParams = getParams(periodicity);
  const [startAt, setStartAt] = React.useState(intitialParams.startAt);
  const [interval, setInterval] = React.useState(intitialParams.interval);

  React.useEffect(() => {
    const { startAt, interval } = getParams(periodicity);

    setStartAt(startAt);
    setInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodicity]);

  return {
    startAt,
    endAt,
    interval,
    periodicity,
    setPeriodicity
  };
}

export const Periodicity = createContainer(usePeriodicity);
