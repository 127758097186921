import * as React from 'react';
import { Box, Code } from '@churni/styleguide';
import { useCompany } from '../containers';
import { urls } from '../../config';
import { Environment as EnvironmentModel } from '@churni/core';
import { useDatabase, useDocument } from '@churni/fire/src';

const codeSnippet = ({ appID }: { appID: string }) => `
 const res = await fetch('${urls.app}/sessions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      appID: '${appID}', // Different between prod/testing envs
      
      // Redirect URL when a user is saved 
      saveReturnURL: 'https://app.example.com/billing',
      // Redirect URL when a user confirms his cancellation
      cancelConfirmationURL: 'https://app.example.com/billing/cancel',

      // Required fields to identify the customer      
      customer: {
        id: customer.id, // Internal identifier
        email: customer.email, // 'user@example.com' 
        name: customer.name // 'John doe',
        domain: customer.domain // 'example.com',
        createdAt: new Date(customer.createdAt).getTime() // Timestamp in ms
      },
      
      variables: {
        planType: 'pro' // optional variables which can be used in your survey 
      }
    })
  });
  
  
  const { url } = await res.json();
  
  // Redirect to churni.io cancel session
  window.location.href = url;
`;

export function Snippet(props: { environmentID: string }): React.ReactElement {
  const company = useCompany();
  const db = useDatabase();

  const environment = useDocument(
    EnvironmentModel.queryByID(db)(company.id, props.environmentID)
  );

  return (
    <Box>
      <Box
        mt={2}
        elevation={1}
        px={2}
        py={1}
        sx={{
          maxHeight: 550,
          overflowY: 'auto',
          borderRadius: 'rounded',
          bg: `rgb(24, 48, 85)`,
          color: 'white'
        }}
      >
        <Code className="js">{codeSnippet({ appID: environment.appID })}</Code>
      </Box>
    </Box>
  );
}
