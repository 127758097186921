import * as React from 'react';
import { createContainer } from 'unstated-next';
import { Box, Text } from '@churni/styleguide';
import { CallBackProps } from 'react-joyride';
import { navigate, routes } from '@churni/common';
import { NewCompany } from './NewCompany';
import { Insights } from './Insights';

function useTour(initialState: boolean) {
  const [tour, setTour] = React.useState(initialState);
  const { setSearch } = Insights.useContainer();
  const [stepIndex, setStepIndex] = React.useState(0);
  const { openPopup } = NewCompany.useContainer();
  let timeout;

  const waitDom = (target, index, delay = 0) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    const elements = document.getElementsByClassName(target);

    if (elements[0]) {
      setTimeout(() => {
        setStepIndex(index);
      }, delay);
    } else {
      timeout = setTimeout(() => {
        waitDom(target, index);
      }, 50);
    }
  };

  const onCallback = (props: CallBackProps) => {
    const { action, index, lifecycle } = props;

    if (action === 'next' && lifecycle === 'complete' && index === 0) {
      setSearch('secondQuestion', 'integration');
      navigate(routes.flow());
      waitDom('page-Rt0LsvxbU5', 1);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 1) {
      window.scrollTo({ top: 800, behavior: 'smooth' });
      setTimeout(() => {
        setStepIndex(2);
      }, 250);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 2) {
      window.scrollTo({ top: 1400, behavior: 'smooth' });
      setTimeout(() => {
        setStepIndex(3);
      }, 250);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 3) {
      setTimeout(() => {
        setStepIndex(4);
      });
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 4) {
      navigate(routes.dashboard());
      waitDom('saveperformance', 5, 100);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 5) {
      navigate(routes.insights());
      waitDom('question-0', 6, 200);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 6) {
      window.scrollTo({ top: 300, behavior: 'smooth' });
      setTimeout(() => {
        setStepIndex(7);
      }, 250);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 7) {
      navigate(routes.customers());
      waitDom('customers_table', 8);
      return;
    }

    if (action === 'next' && lifecycle === 'complete' && index === 8) {
      navigate(routes.customer('gG8QGJeKI9'));
      waitDom('customer', 9);
      return;
    }

    if (
      action === 'skip' ||
      (action === 'next' && lifecycle === 'complete' && index === 9)
    ) {
      setTour(false);
      setTimeout(() => {
        openPopup();
      });
      return;
    }
  };

  const commonProps = {
    floaterProps: {
      offset: 0
    },
    maxWidth: [330, 330, 330, 330, 400],
    offset: 0,
    spotlightPadding: 0,
    hideCloseButton: true,
    disableBeacon: true,
    placement: 'left',
    disableOverlay: false
  };

  const steps = [
    {
      target: '.Cancel.Flow',
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">{`View and edit your cancel flow here.`}</Text>
          </Box>
        </Box>
      ),
      ...commonProps,
      placement: 'right'
    },
    {
      target: '.page-Rt0LsvxbU5  .container',
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Your cancel flow is composed of a series of pages. You can have one or multiple questions per page.`}
            </Text>
          </Box>
        </Box>
      ),

      ...commonProps
    },
    {
      target: '.page-dYOiluahhW  .container',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },

      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Save customers with personalized offers based on their answers or their profile (plan / LTV).`}
            </Text>
          </Box>
        </Box>
      ),
      ...commonProps
    },
    {
      target: '.page-Z5sEkhs0Mf .container',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Choose from different question formats (text, multiple choice) and embed external widgets such as Calendly in your cancellation flow.`}
            </Text>
          </Box>
        </Box>
      ),
      ...commonProps
    },
    {
      target: '.Integrations',
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Add integrations to build a better cancellation process (chatbots, messaging apps).`}
            </Text>
          </Box>
          <Box mt={2}>
            <Text variant="body2">
              {`You can also automate cancellation actions with Webhooks and Zapier.`}
            </Text>
          </Box>
        </Box>
      ),
      ...commonProps,
      placement: 'right'
    },

    {
      target: '.body',
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Follow the performance of your cancellation flow in your dashboard.`}
            </Text>
          </Box>
        </Box>
      ),
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      ...commonProps,
      maxWidth: [290, 290, 290, 290, 400]
    },
    {
      target: '.question-0',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`Get insights on every question in your cancellation flow to help you improve your product.`}
            </Text>
          </Box>
        </Box>
      ),
      ...commonProps
    },
    {
      target: '.question-1',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">
              {`View & search across customers answers for text questions.`}
            </Text>
          </Box>
        </Box>
      ),
      ...commonProps
    },
    {
      target: '.customers_table',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">{`View all customers who have shown an intent to cancel or who have canceled.`}</Text>
          </Box>
        </Box>
      ),
      ...commonProps
    },
    {
      target: '.body',
      styles: {
        spotlight: {
          borderRadius: '10px'
        }
      },
      content: (
        <Box>
          <Box>
            <Text variant="subtitle2">{`Get the reasons why specific customers have canceled.`}</Text>
          </Box>
        </Box>
      ),
      ...commonProps,
      maxWidth: [290, 290, 290, 290, 400]
    }
  ];

  const startTour = () => {
    setTour(true);
  };

  return {
    tour,
    startTour,
    steps,
    onCallback,
    stepIndex
  };
}

export const Tour = createContainer(useTour);
