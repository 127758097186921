export const track = (analytics: firebase.analytics.Analytics) => (
  eventName: string,
  eventParams: object = {}
) => {
  const _hsq = (window._hsq = window._hsq || []);
  _hsq.push([
    'trackEvent',
    {
      id: eventName,
      value: eventParams
    }
  ]);

  analytics.logEvent(eventName, eventParams);
};

// Auth
export const trackSignUp = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Sign-up');
};

export const trackSignIn = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Sign-in');
};

export const trackSignOut = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Sign-out');
};

// Account
export const trackUpdateEmail = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Update Email');
};

export const trackUpdatePassword = (
  analytics: firebase.analytics.Analytics
) => {
  track(analytics)('Update Password');
};

// Workspace
export const trackCreateCompany = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Create Workspace');
};

export const trackJoinCompany = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Join Company');
};

// Form
export const trackUpdateForm = (analytics: firebase.analytics.Analytics) => {
  track(analytics)('Update Form');
};
