import { Member } from '@churni/core';
import { Box, Button, Modal, Popup, Text } from '@churni/styleguide';
import { useCurrentUser, useDatabase, useFirebase } from '@churni/fire';
import * as React from 'react';
import { navigate, routes, analytics } from '@churni/common';
import { SEO } from '../SEO';

export function InviteScreen(props: {
  companyID: string;
  inviteID: string;
}): React.ReactElement {
  const { companyID, inviteID } = props;

  const db = useDatabase();
  const firebase = useFirebase();
  const user = useCurrentUser();

  const onJoin = async () => {
    await Member.joinAsMember(db)(companyID, user.uid, user.email, inviteID);
    analytics.trackJoinCompany(firebase.analytics());
    navigate(routes.app());
  };

  return (
    <Box sx={{ bg: 'background' }}>
      <SEO title={'Join'} />
      <Popup>
        <Modal>
          <Modal.Header>Join workspace</Modal.Header>
          <Modal.Body>
            <Text>Start collaborating with your team by joining them</Text>
          </Modal.Body>
          <Modal.Footer>
            <Box />
            <Button
              variant="primary"
              className="invite-join-team"
              onClick={onJoin}
            >
              Join the team
            </Button>
          </Modal.Footer>
        </Modal>
      </Popup>
    </Box>
  );
}
