import * as React from 'react';
import {
  useCurrentUser,
  useDatabase,
  useStorage,
  useFirebase
} from '@churni/fire';
import commonEmails from 'email-providers/common.json';
import { Company } from '@churni/core';
import {
  Form,
  Input,
  Button,
  Box,
  Text,
  Image,
  FormGroup,
  Icon,
  Tooltip,
  Modal,
  Popup
} from '@churni/styleguide';
import { navigate, routes, analytics } from '@churni/common';
import { Flex } from '@churni/styleguide/src';
import { firestore } from 'firebase';

import { useLogoURL } from './useLogoURL';
import ImagePlaceholder from './assets/image_placeholder.svg';

export function NewCompany(props: { onClose: () => void }): React.ReactElement {
  const { onClose } = props;

  const db = useDatabase();
  const firebase = useFirebase();
  const storage = useStorage();
  const authUser = useCurrentUser();

  const [name, setName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [emailDomain, setEmailDomain] = React.useState(null);

  const [logoURL, setLogoURL] = useLogoURL(name);

  React.useEffect(() => {
    (async () => {
      const emailDomain = authUser.email.split('@')[1];
      if (commonEmails.find(d => d === emailDomain)) {
        return;
      }
      const res = await fetch(
        `https://autocomplete.clearbit.com/v1/companies/suggest?query=${emailDomain}`
      );
      const [suggestion] = await res.json();

      if (emailDomain === suggestion.domain) {
        setEmailDomain(suggestion);
      }
    })();
  }, [authUser.email]);

  React.useEffect(() => {
    if (emailDomain && !name) {
      setName(emailDomain.name);
      setLogoURL(emailDomain.logo);
    }
  }, [emailDomain, name, setLogoURL]);

  const addDays = (days: number) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  const onCreate = async () => {
    setLoading(true);
    const company = Company.create({
      title: name,
      ownerID: authUser.uid,
      createdAt: firestore.Timestamp.now()
    });

    const trialEndDate = addDays(14);

    company.billing.trialEnd = firestore.Timestamp.fromDate(trialEndDate);

    let uploadedLogoURL = null;
    if (logoURL) {
      try {
        const res = await fetch(logoURL);
        const logo = await res.blob();
        logo.name = 'logo.png';

        uploadedLogoURL = await Company.uploadCompanyLogo(storage)(
          company,
          logo
        );
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    company.logoURL = uploadedLogoURL;

    await Company.createCompany(db)(company, authUser.email);
    dataLayer.push({
      event: 'new_company',
      company: {
        id: company.id,
        name: company.title,
        ownerID: company.ownerID
      }
    });
    onClose();
    navigate(routes.onboarding());
  };

  return (
    <>
      <Popup>
        <Modal maxWidth={[350, 700]} p={0}>
          <Flex>
            <Box width={[12, 7]} py={3} pr={4} px={5}>
              <Modal.Header>Start setting up</Modal.Header>

              <Flex
                mt={5}
                sx={{ position: 'relative' }}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {logoURL ? (
                  <>
                    <Box sx={{ right: 8, top: '8px', position: 'absolute' }}>
                      <Tooltip content={'This is not my logo'}>
                        <Button
                          className={'newcompany-reset-logo'}
                          variant="text"
                          icon={<Icon.X />}
                          onClick={() => {
                            setLogoURL(null);
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Image
                      src={logoURL}
                      sx={{
                        borderRadius: 'circle',
                        width: 200,
                        height: 200,
                        border: 1,
                        borderColor: 'border'
                      }}
                    />
                  </>
                ) : (
                  <Box
                    sx={{
                      borderRadius: 'circle',
                      width: 200,
                      height: 200,
                      border: 1,
                      borderColor: 'border',
                      color: 'primary'
                    }}
                    as={ImagePlaceholder}
                  />
                )}
              </Flex>
              <Form onSubmit={onCreate} className="newcompany-form">
                <Box mt={3}>
                  <FormGroup title={'Company name'}>
                    <Flex>
                      <Input
                        value={name}
                        onChangeValue={setName}
                        placeholder={'Pied Piper'}
                        focus
                        sx={{ width: 220 }}
                      />
                      <Button
                        disabled={!name}
                        ml={2}
                        type="submit"
                        loading={loading}
                        className="newcompany-continue-button"
                      >
                        Continue
                      </Button>
                    </Flex>
                  </FormGroup>
                </Box>
                <Flex mt={3} justifyContent={'space-between'}>
                  <Box />
                </Flex>
              </Form>
            </Box>
            <Box
              width={5}
              sx={{
                bg: 'footer',
                borderLeft: 1,
                borderColor: 'border',
                display: ['none', 'block']
              }}
              py={4}
              px={3}
            >
              <Text variant={'heading3'}>14-day pro plan trial</Text>
              <Box>
                <Box py={2}>
                  <Text>
                    Good news! Your company will receive a 14-day free trial of
                    our pro plan which includes:
                  </Text>
                </Box>
                <Box
                  as={'ul'}
                  sx={{ listStyleType: 'none', margin: 0, padding: 1 }}
                >
                  <Box as={'li'} py={'4px'}>
                    <Icon.Check />{' '}
                    <Text variant="body2">Personalized cancellation flow</Text>
                  </Box>
                  <Box as={'li'} py={'4px'}>
                    <Icon.Check />{' '}
                    <Text variant="body2">Dashboard with analytics</Text>
                  </Box>
                  <Box as={'li'} py={'4px'}>
                    <Icon.Check />{' '}
                    <Text variant="body2">Form Embeds & Widgets</Text>
                  </Box>
                  <Box as={'li'} py={'4px'}>
                    <Icon.Check />{' '}
                    <Text variant="body2">Webhooks & Zapier integrations</Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Modal>
      </Popup>
    </>
  );
}
