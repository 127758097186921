import { User } from '@churni/core';
import { Box, Button, Flex, Form, Input, Link, Text } from '@churni/styleguide';
import { analytics, routes, useGoogleLogin } from '@churni/common';
import { useAuth, useFirebase } from '@churni/fire';
import * as React from 'react';
import * as querystring from 'querystring';
import { LoginLayout } from './LoginLayout';
import { SEO } from '../SEO';
import { GoogleButtonWithOneTap } from '@churni/common';

export function Login(props: any): React.ReactElement {
  const { location } = props;
  const { onLogin } = useGoogleLogin();
  const {
    email: qsEmail = '',
    continueURL = '',
    login,
    idToken
  } = querystring.parse(location.search.slice(1));

  const firebase = useFirebase();
  const auth = useAuth;
  const authInstance = useAuth();

  const credentials = idToken
    ? auth.GoogleAuthProvider.credential(idToken)
    : null;

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState(qsEmail);
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState<null | string>(null);

  const onEmailLogin = async () => {
    try {
      setError(null);
      setLoading(true);
      await User.loginWithEmail(authInstance)(email, password);
      analytics.trackSignIn(firebase.analytics());
    } catch (err) {
      setError('The email you entered does not belong to any account.');
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (login) {
      onLogin();
    }
  }, [login, onLogin]);

  React.useEffect(() => {
    if (idToken) {
      authInstance.signInWithCredential(credentials).then(user => {
        if (user.additionalUserInfo.isNewUser) {
          dataLayer.push({
            event: 'signup',
            type: 'google'
          });
        }
      });
    }
  }, [authInstance, credentials, idToken]);

  const onSubmit = async () => {
    await onEmailLogin();
  };

  return (
    <>
      <SEO title={'Sign in'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            <Flex justifyContent="space-between" alignItems={'center'} mb={1}>
              <Box>
                <Text variant="heading2">Sign in</Text>
              </Box>
            </Flex>
            <Flex mt={2}>
              <GoogleButtonWithOneTap />
            </Flex>
            <Box
              mt={2}
              mb={1}
              sx={{ borderTop: 1, borderColor: 'border', width: '100%' }}
            />
            <Box pb={2} sx={{ textAlign: 'center' }}>
              <Text color={'secondaryText'}>or</Text>
            </Box>
            <Form onSubmit={onSubmit} className="signin-email-form">
              <Box pb={2}>
                <Input
                  focus
                  bg="white"
                  placeholder="Email"
                  value={email}
                  onChangeValue={setEmail}
                />
              </Box>
              <Box pb={2}>
                <Input
                  bg="white"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChangeValue={setPassword}
                />
              </Box>
              <Flex>
                <Button
                  flex={1}
                  className="signin-email"
                  variant="primaryOutline"
                  type="submit"
                  loading={loading}
                >
                  <Text>Sign in with email</Text>
                </Button>
              </Flex>
            </Form>
            <Flex mt={2} justifyContent={'flex-end'}>
              <Link to={routes.forgot({ email })}>
                <Text variant="body2" color={'secondaryText'}>
                  Forgot password?
                </Text>
              </Link>
            </Flex>
          </Box>
        }
        footer={
          <Box mt={2}>
            <Box mt={1}>
              <Text variant={'body2'}>{`Don't have an account?`}</Text>
            </Box>
            <Box>
              <Link to={routes.signup({ email, continueURL })}>
                <Text variant={'body2'} color="primary">
                  Sign up for free
                </Text>
              </Link>
            </Box>
          </Box>
        }
      />
    </>
  );
}
