import { User } from '@churni/core';
import { Box, Button, Flex, Form, Input, Link, Text } from '@churni/styleguide';
import { routes } from '@churni/common';
import { useAuth } from '@churni/fire';

import * as React from 'react';
import { LoginLayout } from './LoginLayout';
import { SEO } from '../SEO';

export function Forgot(): React.ReactElement {
  const auth = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState<null | string>(null);
  const [success, setSuccess] = React.useState(false);

  const onPasswordReset = async () => {
    try {
      setError(null);
      setLoading(true);
      await User.resetPassword(auth)(email);
      setSuccess(true);
    } catch (err) {
      setError('The email you entered does not belong to any account.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO title={'Forgot password'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            {success ? (
              <>
                <Box>
                  <Text>{`Please check your email. We sent en email to ${email}, which contains a link to reset your password.`}</Text>
                </Box>
              </>
            ) : (
              <>
                <Text color="secondaryText">
                  Enter your email address and we’ll send you a link to reset
                  your password.
                </Text>

                <Form onSubmit={onPasswordReset}>
                  <Box py={2}>
                    <Input
                      focus
                      placeholder="Email"
                      value={email}
                      onChangeValue={setEmail}
                      bg={'white'}
                    />
                  </Box>

                  <Flex>
                    <Button
                      className="fogot-send-password"
                      flex={1}
                      type="submit"
                      onClick={onPasswordReset}
                      loading={loading}
                    >
                      <Text>Send password reset link</Text>
                    </Button>
                  </Flex>
                </Form>
              </>
            )}
          </Box>
        }
        footer={
          <Box mt={2}>
            <Link to={routes.login({ email })}>
              <Text variant={'body2'} color="primary">
                Back to sign in
              </Text>
            </Link>
          </Box>
        }
      />
    </>
  );
}
