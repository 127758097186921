import { firestore } from 'firebase';
import { genID, setDocument } from '../helpers';
import { Company } from './Company';

export interface InviteInterface {
  id: string;
}
export const Invite = {
  create(form: Partial<InviteInterface> = {}): InviteInterface {
    const defaultValues = {
      id: genID('invite')
    };

    return {
      ...defaultValues,
      ...form
    };
  },
  queryByCompany(db: firestore.Firestore) {
    return (companyID: string) => {
      return Company.queryByID(db)(companyID).collection('invites');
    };
  },

  queryByID(db: firestore.Firestore) {
    return (companyID: string, inviteID: string) => {
      return Company.queryByID(db)(companyID)
        .collection('invites')
        .doc(inviteID);
    };
  },

  generateInvite(db: firestore.Firestore) {
    return async (companyID: string): Promise<InviteInterface> => {
      const invite = Invite.create();

      await setDocument(Invite.queryByID(db)(companyID, invite.id), invite);

      return invite;
    };
  }
};
