import { firestore } from 'firebase';
import { genID } from '../helpers';
import { Revision } from './Revision';
import { Company } from './Company';

export interface FormInterface {
  id: string;
  title: string;
  companyID: string;
  primaryRevision: string;
}

export const Form = {
  create(form: Partial<FormInterface> = {}): FormInterface {
    const defaultValues = {
      id: genID('form'),
      title: '',
      companyID: '',
      primaryRevision: ''
    };

    return {
      ...defaultValues,
      ...form
    };
  },

  queryByID(db: firestore.Firestore) {
    return (companyID: string, formID: string) => {
      return Company.queryByID(db)(companyID)
        .collection('forms')
        .doc(formID);
    };
  },
  /*
   * Create a form
   */
  createForm(db: firestore.Firestore) {
    return async (
      companyID: string,

      title: string
    ): Promise<FormInterface> => {
      const batch = db.batch();

      const revision = Revision.create({
        value: []
      });
      const form = Form.create({
        title,
        companyID,

        primaryRevision: revision.id
      });

      batch.set(Form.queryByID(db)(companyID, form.id), form);
      batch.set(
        Revision.queryByID(db)(companyID, form.id, revision.id),
        revision
      );

      await batch.commit();

      return form;
    };
  },

  updateFormContent(db: firestore.Firestore) {
    return async (companyID: string, formID: string, value: object[]) => {
      const batch = db.batch();

      const revision = Revision.create({ value });
      const newForm = { primaryRevision: revision.id };

      batch.update(Form.queryByID(db)(companyID, formID), newForm);
      batch.set(
        Revision.queryByID(db)(companyID, formID, revision.id),
        revision
      );

      await batch.commit();
    };
  },

  updateFormTitle(db: firestore.Firestore) {
    return async (companyID: string, formID: string, title: string) => {
      await Form.queryByID(db)(companyID, formID).update({
        title
      });
    };
  },

  updateFormColor(db: firestore.Firestore) {
    return async (companyID: string, formID: string, color: string) => {
      await Form.queryByID(db)(companyID, formID).update({
        color
      });
    };
  },

  deleteForm(db: firestore.Firestore) {
    return async (companyID: string, formID: string) => {
      await Form.queryByID(db)(companyID, formID).delete();
    };
  }
};
