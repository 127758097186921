import * as React from 'react';
import { createContainer } from 'unstated-next';

function useInsightsState() {
  const [searches, setSearches] = React.useState({});

  const setSearch = (questionID: string, search: string) => {
    setSearches({
      ...searches,
      [questionID]: search
    });
  };

  const getSearch = (questionID: string) => {
    return searches[questionID];
  };

  return {
    getSearch,
    setSearch,
    searches
  };
}

export const Insights = createContainer(useInsightsState);
