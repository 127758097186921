import * as React from 'react';

export interface SessionState {
  currentPage: number;
  previous: () => void;
  next: () => void;
  onCancel: (status: string) => void;
  onFinish: () => void;
  errors: { error: string; type: string }[];
  setErrors: (errors: { error: string; type: string }[]) => void;
  reset: () => void;
}

export const SessionStateContext = React.createContext<SessionState | {}>({});

export function useSessionState(
  onFinish: () => Promise<void>,
  onCancel: (status: string) => Promise<void>
): SessionState {
  const [currentPage, setCurrentPage] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [jumpsState, setJumpsState] = React.useState({});

  const previous = (previousPage?: number) => {
    setErrors([]);
    setCurrentPage(
      Math.max(0, previousPage !== undefined ? previousPage : currentPage - 1)
    );
  };

  const next = (nextPage?: number) => {
    setErrors([]);
    setCurrentPage(nextPage || currentPage + 1);
  };

  const reset = () => {
    setErrors([]);
    setCurrentPage(0);
  };

  return {
    currentPage,
    previous,
    next,
    onFinish,
    onCancel,
    errors,
    setErrors,
    reset,
    jumpsState,
    setJumpsState
  };
}
