import * as React from 'react';
import {
  Section,
  FormGroup,
  Input,
  Button,
  Box,
  Image,
  useFormState,
  Link,
  Flex,
  SectionActions
} from '@churni/styleguide';
import { useCompany } from '../../containers';
import { Company } from '@churni/core';
import { useDatabase, useStorage } from '@churni/fire';

export function SettingsInformation(): React.ReactElement {
  const db = useDatabase();
  const storage = useStorage();
  const company = useCompany();
  const [title, setTitle, hasTitleChanged] = useFormState(company.title);
  const [logo, setLogo, hasLogoChanged] = useFormState(company.logoURL);
  const [file, setFile] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleImage = (event: React.SyntheticEvent) => {
    if (event.target.files[0]) {
      setLogo(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  };

  const onSave = async () => {
    setLoading(true);
    if (hasTitleChanged) {
      await Company.queryByID(db)(company.id).update({ title });
    }

    if (hasLogoChanged) {
      const logoURL = await Company.uploadCompanyLogo(storage)(company, file);
      await Company.queryByID(db)(company.id).update({ logoURL });
    }
    setLoading(false);
  };

  return (
    <Section title={'Information'}>
      <FormGroup title={'Company Name'}>
        <Input value={title} onChangeValue={setTitle} />
      </FormGroup>
      <FormGroup title={'Logo'} mt={2}>
        <Flex alignItems="center">
          <Image
            src={logo}
            sx={{
              borderRadius: 'circle',
              width: 50,
              height: 50,
              border: 1,
              borderColor: 'border'
            }}
          />
          <Box ml={2}>
            <Link
              variant={'primary'}
              for={'file'}
              as={'label'}
              sx={{ cursor: 'pointer' }}
            >
              Upload new image
            </Link>
            <Input
              id={'file'}
              accept="image/*"
              sx={{ display: 'none' }}
              type={'file'}
              onChange={handleImage}
            />
          </Box>
        </Flex>
      </FormGroup>
      <SectionActions>
        <Box />
        <Button
          className="settings-info-save"
          onClick={onSave}
          loading={loading}
          disabled={!hasTitleChanged && !hasLogoChanged}
        >
          Save changes
        </Button>
      </SectionActions>
    </Section>
  );
}
