/* eslint-disable @typescript-eslint/camelcase */
import { useState, useEffect } from 'react';
import { useAuth } from '@churni/fire';
import { firebase } from '../../app/src/config';
import { routes } from './router';
import { waitDom } from './waitDom';

function watchIframeContainer() {
  return new Promise((resolve, reject) => {
    let height = 0;
    const wrapper = window.document.getElementById('google_one_tap');

    const bodyObserver = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          const attributeObserver = new MutationObserver(
            iframeMutationsList => {
              height = parseInt(iframeMutationsList[0].target.style.height);

              if (height) {
                resolve();
              }
            }
          );

          attributeObserver.observe(node, { attributes: true });
        });
      });
    });

    bodyObserver.observe(wrapper, { childList: true });
  });
}

export const useGoogleOneTap = (external = false, context = 'signup') => {
  const authInstance = useAuth();
  const auth = useAuth;

  const [idToken, setIdToken] = useState(null);
  const credentials = idToken
    ? auth.GoogleAuthProvider.credential(idToken)
    : null;
  const [loading, setLoading] = useState(true);

  const [shouldShowFallbackButton, setShouldShowFallbackButton] = useState(
    false
  );

  useEffect(
    () => {
      const handleCredentialResponse = response => {
        setIdToken(response.credential);
      };

      // Use MutationObserver to set load at false
      watchIframeContainer().then(() => {
        setLoading(false);
      });

      waitDom('google', () => {
        google.accounts.id.initialize({
          client_id: firebase.authClientID,
          callback: handleCredentialResponse,
          context,
          cancel_on_tap_outside: false,
          prompt_parent_id: 'google_one_tap'
        });

        google.accounts.id.prompt(notification => {
          // console.log('notification is: ', notification.getMomentType());

          if (notification.isDisplayMoment()) {
            // console.log('IS DISPLAY MOMENT');
          }

          if (notification.isNotDisplayed()) {
            console.warn(
              'one-tap did not show because:',
              notification.getNotDisplayedReason()
            );
            setShouldShowFallbackButton(true);
          }
          if (notification.isSkippedMoment()) {
            console.warn(
              'one-tap skipped because:',
              notification.getSkippedReason()
            );
            setShouldShowFallbackButton(true);
          }
          if (notification.isDismissedMoment()) {
            console.warn(
              'one-tap dismissed because:',
              notification.getDismissedReason()
            );
            if (notification.getDismissedReason() !== 'credential_returned') {
              setShouldShowFallbackButton(true);
            }
          }
        });
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (idToken) {
      if (external) {
        window.location.href = routes.toAppURL(routes.googleSSO({ idToken }));
      } else {
        // Sign in with credential from the Google user.
        authInstance.signInWithCredential(credentials).then(user => {
          if (user.additionalUserInfo.isNewUser) {
            dataLayer.push({
              event: 'signup',
              type: 'google'
            });
          }
        });
      }
    }
  }, [auth, authInstance, credentials, external, idToken]);

  return {
    shouldShowFallbackButton,
    setShouldShowFallbackButton,
    loading
  };
};
