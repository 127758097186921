import * as React from 'react';
import { Box } from '@churni/styleguide';
import { useCustomersResource } from '../common';
import { QuestionCard } from './QuestionCard';
import { TextQuestionCard } from './TextQuestionCard';
import { Periodicity } from '../../containers';

export function Insights(): React.ReactElement {
  const periodicityParams = Periodicity.useContainer();
  const { customersResource } = useCustomersResource({ periodicityParams });

  const {
    data: { questions }
  } = customersResource.read();

  // dunno why it's necessary but otherwise the page sometines scroll
  React.useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!questions.length) {
    return <Box>No sessions found</Box>;
  }

  return (
    <Box>
      {questions.map((q, index) => {
        const questionProps = index === questions.length - 1 ? {} : { mb: 4 };

        if (q.infos.type === 'multiple-choice') {
          return (
            <QuestionCard
              {...questionProps}
              key={q.key}
              question={q}
              className={`question-${index} question`}
            />
          );
        }

        if (q.infos.type === 'text') {
          return (
            <TextQuestionCard
              {...questionProps}
              key={q.key}
              question={q}
              periodicityParams={periodicityParams}
              className={`question-${index} question`}
            />
          );
        }

        return null;
      })}
    </Box>
  );
}
