import { useGoogleLogin, LoadingPage } from '@churni/common';
import { useAuth } from '@churni/fire';
import * as React from 'react';
import * as querystring from 'querystring';

export function GoogleSSO(props: any): React.ReactElement {
  const { location } = props;
  const { onLogin } = useGoogleLogin();
  const { login, idToken } = querystring.parse(location.search.slice(1));

  const auth = useAuth;
  const authInstance = useAuth();

  const credentials = idToken
    ? auth.GoogleAuthProvider.credential(idToken)
    : null;

  React.useEffect(() => {
    if (login) {
      onLogin();
    }
  }, [login, onLogin]);

  React.useEffect(() => {
    if (idToken) {
      authInstance.signInWithCredential(credentials).then(user => {
        if (user.additionalUserInfo.isNewUser) {
          dataLayer.push({
            event: 'signup',
            type: 'google'
          });
        }
      });
    }
  }, [authInstance, credentials, idToken]);

  return <LoadingPage />;
}
