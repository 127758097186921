import * as React from 'react';
import { Box, Text, Grid } from '@churni/styleguide';
import { Redirect, routes } from '@churni/common';

import { SEO } from '../../SEO';

import { useCustomerResource } from '../common';
import { Breadcrumb } from '../../../../../website/src/components/Blog';

export function Customer(props: { id: string }): React.ReactElement {
  const { id: customerID } = props;

  const { customerResource } = useCustomerResource({ customerID });

  const { data: customer } = customerResource.read();

  if (!customer) {
    return <Redirect to={routes.customers()} noThrow={true} />;
  }

  const customerInfo = customer.customer;

  const customerInfoCreatedAt = customerInfo
    ? new Date(customer.createdAt).toLocaleDateString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
    : null;

  const customerHasAnsweredQuestions = customer.questions.some(q =>
    q.type === 'text' ? q.answer : q.answers[0] && q.answers[0].text
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <SEO title={'Customer'} />

      <Breadcrumb
        breadcrumbElements={[
          { title: 'Customers', url: routes.customers() },
          { title: customerInfo.name, url: routes.customer(customerID) }
        ]}
      />

      <Box py={2} mt={2} className={'customer'}>
        <Grid columns={[1, 1, 2]} rowGap={1}>
          <Box>
            <b>Cancel Date</b>: {customerInfoCreatedAt},
          </Box>
          <Box>
            <b>Subscription status</b>: {customer.status}
          </Box>
          <Box>
            <b>Subscription length</b>: {customerInfo.subscriptionLength} months
          </Box>
          <Box>
            <b>Domain</b>: {customerInfo.domain}
          </Box>
        </Grid>

        <Box mt={3}>
          {customerHasAnsweredQuestions ? (
            customer.questions.map(question => {
              const answer =
                question.type === 'text'
                  ? question.answer
                  : question.answers[0] && question.answers[0].text;

              if (!answer) {
                return null;
              }

              return (
                <Box key={question.id} my={3}>
                  <Box>
                    <Text variant="subtitle2">{question.title}</Text>
                  </Box>
                  <Box>{answer}</Box>
                </Box>
              );
            })
          ) : (
            <Box>No answers yet</Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
