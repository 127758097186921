import {
  Box,
  Button,
  Form,
  FormGroup,
  Input,
  Text,
  useFormState,
  Section,
  SectionActions
} from '@churni/styleguide';
import { useAuth, useAuthUser, useFirebase } from '@churni/fire';
import * as React from 'react';
import { ReauthenticatePopup } from './ReauthenticatePopup';
import { User } from '@churni/core';
import { analytics } from '@churni/common';

export function Account(): React.ReactElement {
  return <AccountContent />;
}

export function AccountContent(): React.ReactElement {
  const initialUser = useAuthUser();
  const auth = useAuth();
  const firebase = useFirebase();

  const [email, setEmail, hasEmailChanged] = useFormState(initialUser.email);
  const [password, setPassword, hasPasswordChanged] = useFormState('');
  const [error, setError] = React.useState<Error | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [
    displayReauthenticatePopup,
    setDisplayReauthenticatePopup
  ] = React.useState(false);

  const onSave = async () => {
    setError(null);
    setSuccess(null);

    try {
      if (hasEmailChanged) {
        analytics.trackUpdateEmail(firebase.analytics());
        await User.updateEmail(auth)(email);
      }

      if (hasPasswordChanged) {
        analytics.trackUpdatePassword(firebase.analytics());
        await User.updatePassword(auth)(password);
      }

      setSuccess('Your personal info has been successfully updated');
    } catch (err) {
      if (err.code === 'auth/weak-password') {
        setError(err);
      }

      if (err.code === 'auth/requires-recent-login') {
        setDisplayReauthenticatePopup(true);
      }
    }
  };

  const onSuccess = async () => {
    setDisplayReauthenticatePopup(false);
    await onSave();
  };

  return (
    <>
      {error && (
        <Box>
          <Text color={'error'}>{error.message}</Text>
        </Box>
      )}
      {success && (
        <Box>
          <Text color={'success'}> {success}</Text>
        </Box>
      )}

      <Section title="Credentials">
        <Form onSubmit={onSave}>
          <FormGroup title={'Email Address'}>
            <Input value={email} onChangeValue={setEmail} />
          </FormGroup>
          <FormGroup title={'Password'} mt={2}>
            <Input
              value={password}
              onChangeValue={setPassword}
              type="password"
            />
          </FormGroup>

          <SectionActions>
            <Box />
            <Button
              className="account-save"
              kind={'primary'}
              onClick={onSave}
              disabled={!hasEmailChanged && !hasPasswordChanged}
            >
              Save changes
            </Button>
          </SectionActions>
        </Form>
      </Section>

      <Box mt={3}>
        <Box>
          <Text variant={'subtitle3'}>Other</Text>
        </Box>
        <Box>
          <Button
            className={'account-logout'}
            onClick={() => {
              analytics.trackSignOut(firebase.analytics());
              User.logout(auth)();
            }}
          >
            Logout
          </Button>
        </Box>
      </Box>

      {displayReauthenticatePopup && (
        <ReauthenticatePopup
          onClose={() => setDisplayReauthenticatePopup(false)}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
}
