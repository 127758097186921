export const waitDom = (target, callback, timeout) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  if (window[target]) {
    callback();
  } else {
    timeout = setTimeout(() => {
      waitDom(target, callback, timeout);
    }, 50);
  }
};
