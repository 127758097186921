import { Invite } from '@churni/core';
import {
  Button,
  Icon,
  Input,
  Modal,
  Popup,
  Text,
  Box
} from '@churni/styleguide';
import { routes } from '@churni/common';
import { useCollection, useDatabase } from '@churni/fire';
import * as React from 'react';
import { PopupPlaceholder } from '@churni/styleguide/src';
import { useCompany } from '../../containers';

export function InviteModal(props: {
  onClose: () => void;
}): React.ReactElement {
  const { onClose } = props;

  const company = useCompany();
  const db = useDatabase();

  const invites = useCollection<InviteInterface>(
    Invite.queryByCompany(db)(company.id)
  );

  const firstInvite = invites[0];

  if (!firstInvite) {
    return <PopupPlaceholder />;
  }

  return (
    <Popup onClose={onClose}>
      <Box>
        <Modal>
          <Modal.Header>Invite members</Modal.Header>
          <Modal.Body>
            <Text variant={'body1'}>
              Share this url to invite your team in this workspace:
            </Text>
            <Input
              mt={2}
              label="URL"
              value={routes.toAppURL(
                routes.toInvite(company.id, firstInvite.id)
              )}
              readOnly
              icon={<Icon.Link />}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={'cancel'}
              onClick={onClose}
              className="invite-popup-cancel"
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Box>
    </Popup>
  );
}
