/* eslint-disable @typescript-eslint/camelcase */
import {
  useAuthUser,
  useCollection,
  useCurrentUser,
  useDatabase,
  useDocument,
  useAuth
} from '@churni/fire';
import {
  CompanyInterface,
  MemberInterface,
  Member,
  Company
} from '@churni/core';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { Helmet } from 'react-helmet';
import * as React from 'react';
import { Account } from './Settings/Account';
import { Forgot, Login, Signup, GoogleSSO } from './Login';
import {
  ErrorWrapper,
  Router,
  LoadingPage,
  Redirect,
  useLocation,
  routes
} from '@churni/common';
import { Suspense, Box } from '@churni/styleguide';
import { Global } from '@emotion/core';
import { FormEditor, FormEditorDemo } from './FormEditor';
import { InviteScreen } from './InviteScreen';
import { Authorize } from './Login/Authorize';
import { LoginToken } from './Login/LoginToken';
import { NewCompany } from './NewCompany';
import { NewDashboard } from './NewDashboard';
import * as querystring from 'querystring';
import { stripe } from '../config';
import { Settings } from './Settings';
import { UpgradeModal } from './Settings/Billing/UpgradeModal';
import { DashboardLayout } from './DashboardLayout';
import { SetupModal } from './Onboarding/SetupModal';
import { WelcomeModal } from './Onboarding/WelcomeModal';
import { InviteModal } from './Settings/General/InviteModal';

import { AuthRouteAction } from './Login/AuthRouteAction';
import {
  MembershipContext,
  CompanyContext,
  NewCompany as NewCompanyContainer,
  Environment,
  Upgrade,
  Welcome,
  Setup,
  FormState,
  Tour,
  Invite,
  Insights as InsightsContainer,
  Periodicity
} from './containers';
import { SentryProviderWithUser } from '@churni/common';
import { SEO } from './SEO';
import { waitForGlobal } from './Settings/waitForGlobal';

const GettingStarted = React.lazy(() =>
  import('./Onboarding' /* webpackChunkName: "onboarding" */)
);

const Integrations = React.lazy(() =>
  import('./Integrations' /* webpackChunkName: "integrations" */)
);

export default function ApplicationRouter() {
  // Ugly workaround as on load doesnt work
  React.useEffect(() => {
    waitForGlobal('Paddle').then(() => {
      window.Paddle.Setup({ vendor: 110818 });
    });
  }, []);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Helmet>
        <script
          async
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
        ></script>

        <script src="https://cdn.paddle.com/paddle/paddle.js"></script>
      </Helmet>
      <SentryProviderWithUser />
      <SEO />
      <Global
        styles={theme => {
          return {
            body: {
              fontFamily: theme.fonts.body,
              fontSize: theme.fontSizes[theme.text.body1.fontSize],
              fontWeight: theme.fontWeights[theme.text.body1.fontWeight],
              letterSpacing: theme.text.body1.letterSpacing,
              color: theme.colors.text,
              backgroundColor: theme.colors.background,
              margin: 0,
              padding: 0,
              overflowX: 'hidden',
              overflowY: 'scroll'
            }
          };
        }}
      />
      <ErrorWrapper>
        <ApplicationRoutes />
      </ErrorWrapper>
    </Suspense>
  );
}

function ApplicationRoutes() {
  const user = useAuthUser();
  const auth = useAuth();
  const { location } = useLocation();
  const qs = querystring.parse(location.search.slice(1));

  React.useEffect(() => {
    auth.getRedirectResult().then(userCredential => {
      if (
        userCredential &&
        userCredential.additionalUserInfo &&
        userCredential.additionalUserInfo.isNewUser
      ) {
        dataLayer.push({
          event: 'signup',
          type: 'google'
        });
      }
    });
    // eslint-disable-next-line
  }, [user]);

  React.useEffect(() => {
    if (user) {
      dataLayer.push({
        event: 'identify_user',
        user: {
          id: user.uid,
          email: user.email,
          emailVerified: user.emailVerified,
          userName: user.displayName
        }
      });
    }
  }, [user]);

  if (!user) {
    return (
      <Router>
        <Box path="/">
          <Authorize path={'authorize'} />
          <GoogleSSO path={'login/google'} />
          <LoginToken path={'login/token'} />
          <Login path={'login/*'} />
          <Signup path={'signup/*'} />
          <Forgot path={'forgot'} />
          <AuthRouteAction path={'auth'} />
          <Redirect
            noThrow={true}
            from={'*'}
            to={routes.login({ continueURL: location.pathname })}
          />
        </Box>
      </Router>
    );
  }

  return (
    <Router>
      <Box path="/">
        <Redirect noThrow={true} from={'login'} to={qs.continueURL || '/'} />
        <Redirect noThrow={true} from={'signup'} to={qs.continueURL || '/'} />
        <Redirect noThrow={true} from={'login/google'} to={'/'} />
        <Authorize path={'authorize'} />
        <AuthRouteAction path={'auth'} />
        <InviteScreen path={'invite/:companyID/:inviteID'} />
        <MembershipRouter path={'*'} />
      </Box>
    </Router>
  );
}

function MembershipRouter() {
  const db = useDatabase();
  const authUser = useCurrentUser();
  const { location } = useLocation();
  const memberships = useCollection<MemberInterface>(
    Member.queryByUser(db)(authUser.uid)
  );

  return (
    <Upgrade.Provider initialState={false}>
      <Welcome.Provider
        initialState={!memberships.length && !location.pathname.match(/invite/)}
      >
        <NewCompanyContainer.Provider initialState={false}>
          <Setup.Provider initialState={false}>
            <Invite.Provider initialState={false}>
              <MembershipContext.Provider
                value={memberships.length ? memberships[0] : null}
              >
                {memberships[0] ? (
                  <CompanyRoutesWithMember member={memberships[0]} />
                ) : (
                  <CompanyRoutes
                    company={Company.create({
                      id: 'demo',
                      title: 'demo',
                      slug: 'demo',
                      ownerID: 'demo'
                    })}
                  />
                )}
              </MembershipContext.Provider>
            </Invite.Provider>
          </Setup.Provider>
        </NewCompanyContainer.Provider>
      </Welcome.Provider>
    </Upgrade.Provider>
  );
}

export function CompanyRoutesWithMember(props: {
  member: MemberInterface;
}): React.ReactElement {
  const db = useDatabase();
  const { member } = props;

  const company = useDocument<CompanyInterface>(
    Company.queryByID(db)(member.companyID)
  );

  if (!company) {
    return null;
  }

  return <CompanyRoutes company={company} />;
}

function CompanyRoutes(props: {
  company: CompanyInterface;
}): React.ReactElement {
  const { company } = props;

  const {
    opened: upgradeOpened,
    closePopup: closeUpgradePopup
  } = Upgrade.useContainer();
  const {
    opened: newCompanyOpened,
    closePopup: closeNewCompanyPopup
  } = NewCompanyContainer.useContainer();
  const {
    opened: welcomeOpened,
    closePopup: closeWelcomePopup
  } = Welcome.useContainer();
  const {
    opened: setupOpened,
    closePopup: closeSetupPopup
  } = Setup.useContainer();

  const {
    opened: inviteOpened,
    closePopup: closeInvitePopup
  } = Invite.useContainer();

  React.useEffect(() => {
    if (company) {
      dataLayer.push({
        event: 'identify_company',
        company: {
          id: company.id,
          ownerID: company.ownerID,
          title: company.title,
          onboarding: company.onboarding,
          setup: company.setup,
          billing: {
            trialEnd: company.billing.trialEnd
              ? company.billing.trialEnd.toMillis()
              : null,
            quantity: company.billing.quantity,
            periodicity: company.billing.periodicity,
            productID: company.billing.productID,
            planID: company.billing.planID,
            customerID: company.billing.customerID,
            subscriptionID: company.billing.subscriptionID,
            type: company.billing.type
          }
        }
      });
    }
  }, [company]);

  if (!company || !company.setup) {
    return null;
  }

  return (
    <CompanyContext.Provider value={company}>
      <StripeProvider
        stripe={window.Stripe ? window.Stripe(stripe.publicKey) : null}
      >
        <FormState.Provider>
          <InsightsContainer.Provider>
            <Periodicity.Provider>
              <Tour.Provider initialState={false}>
                <Environment.Provider
                  initialState={
                    company.setup.production ? 'production' : 'testing'
                  }
                >
                  <DashboardLayout>
                    <>
                      <Router>
                        <Account path={'/account'} activeTab={'account'} />
                        <Settings path={'/settings/*'} activeTab={'settings'} />
                        <NewDashboard
                          path={'/dashboard/*'}
                          activeTab={'dashboard'}
                        />
                        {company.id === 'demo' ? (
                          <FormEditorDemo path={'/cancel-flow'} />
                        ) : (
                          <FormEditor
                            path={'/cancel-flow'}
                            activeTab={'cancel-flow'}
                          />
                        )}
                        <Integrations
                          path={'/integrations/*'}
                          activeTab={'integrations'}
                        />
                        <GettingStarted path={'/getting-started'} />
                        <Redirect
                          noThrow={true}
                          from={'/'}
                          to={
                            company.id !== 'demo' &&
                            !company.onboarding.completed
                              ? routes.gettingStarted()
                              : routes.dashboard()
                          }
                        />
                      </Router>
                    </>
                  </DashboardLayout>

                  {welcomeOpened && (
                    <WelcomeModal onClose={closeWelcomePopup} />
                  )}

                  {upgradeOpened && (
                    <Elements>
                      <UpgradeModal onClose={closeUpgradePopup} />
                    </Elements>
                  )}

                  {newCompanyOpened && (
                    <NewCompany onClose={closeNewCompanyPopup} />
                  )}
                  {setupOpened && <SetupModal onClose={closeSetupPopup} />}
                  {inviteOpened && <InviteModal onClose={closeInvitePopup} />}
                </Environment.Provider>
              </Tour.Provider>
            </Periodicity.Provider>
          </InsightsContainer.Provider>
        </FormState.Provider>
      </StripeProvider>
    </CompanyContext.Provider>
  );
}
