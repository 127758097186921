export const demoValue = [
  {
    type: 'header',

    children: [
      {
        type: 'heading-1',

        children: [{ text: 'Pied Piper' }]
      },
      {
        type: 'paragraph',

        children: [
          {
            text:
              'To process your cancellation, please follow the instructions below.'
          }
        ]
      }
    ]
  },
  {
    type: 'page',
    children: [
      {
        type: 'page-body',

        children: [
          {
            type: 'question',
            questionType: 'multiple-choice',
            id: 'RRzEDNiz8y',
            required: true,

            children: [
              {
                type: 'question-title',

                children: [
                  {
                    text: "What's your primary reason for canceling?"
                  }
                ]
              },
              {
                type: 'question-body',

                children: [
                  {
                    type: 'question-multiple-choice-item',

                    children: [
                      {
                        text: "Don't use it enough"
                      }
                    ],
                    id: 'PY4pq13gyR'
                  },
                  {
                    type: 'question-multiple-choice-item',
                    id: '7iFZS4iPFI',

                    children: [
                      {
                        text: 'Missing features'
                      }
                    ]
                  },
                  {
                    type: 'question-multiple-choice-item',
                    id: 'JiOlIxlRyK',

                    children: [
                      {
                        text: 'Too expensive'
                      }
                    ]
                  },
                  {
                    type: 'question-multiple-choice-item',
                    id: 'zSCYETMCrV',

                    children: [
                      {
                        text: 'Need more support'
                      }
                    ]
                  },
                  {
                    type: 'question-multiple-choice-item',

                    children: [
                      {
                        text: 'Found another solution'
                      }
                    ],
                    id: 'vRa7uMi0H6'
                  },
                  {
                    type: 'question-multiple-choice-item',
                    id: 'Y0ogel9cco',

                    children: [
                      {
                        text: 'Difficult to setup / Too complex'
                      }
                    ]
                  },
                  {
                    type: 'question-multiple-choice-item',

                    children: [
                      {
                        text: 'Other'
                      }
                    ],
                    id: 'KBQiiZUETy'
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'page-actions',

        children: [
          {
            type: 'page-action-primary',
            action: 'continue',

            children: [
              {
                text: 'Continue'
              }
            ]
          }
        ]
      }
    ],
    id: 'Rt0LsvxbU5'
  },
  {
    type: 'jump',
    condition: 'question',
    questionID: 'RRzEDNiz8y',
    answerID: 'Y0ogel9cco',
    pageID: 'dYOiluahhW',

    children: [
      {
        text: ''
      }
    ],
    id: 'MO5vXbWgMJ'
  },

  {
    type: 'jump',
    condition: 'always',
    pageID: 'Z5sEkhs0Mf',

    children: [
      {
        text: ''
      }
    ],
    id: 'yiZ7LP2H02'
  },
  {
    type: 'page',
    id: 'dYOiluahhW',

    children: [
      {
        type: 'page-body',

        children: [
          {
            type: 'heading-1',

            children: [
              {
                text:
                  'We can help you to get started. Would you be interested in a free month & help?'
              }
            ]
          },
          {
            type: 'paragraph',

            children: [
              {
                text: `We'll schedule a call with a team member of our team to help you get started`
              }
            ]
          }
        ]
      },
      {
        type: 'page-actions',

        children: [
          {
            type: 'page-action-secondary',

            children: [{ text: 'No, I want to cancel now' }]
          },
          {
            type: 'page-action-primary',
            action: 'abandon',
            status: 'offer_accepted',

            children: [{ text: 'Get a Free Month' }]
          }
        ]
      }
    ]
  },

  {
    type: 'page',
    id: 'Z5sEkhs0Mf',

    children: [
      {
        type: 'page-body',

        children: [
          {
            type: 'question',
            required: true,
            questionType: 'text',

            children: [
              {
                type: 'question-title',

                children: [
                  {
                    text:
                      'We would love to know what we can do to make this product better.'
                  }
                ]
              },
              {
                type: 'question-body',

                children: [
                  {
                    type: 'question-text-answer',

                    children: [
                      {
                        type: 'paragraph',

                        children: [
                          {
                            text: ''
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ],
            id: 'Rxg3afHplb'
          }
        ]
      },
      {
        type: 'page-actions',

        children: [
          {
            type: 'page-action-primary',
            action: 'continue',

            children: [{ text: 'Cancel my subscription' }]
          }
        ]
      }
    ]
  }
];
