import * as React from 'react';
import { Box, Flex } from '@churni/styleguide';
import { Router, routes, Redirect } from '@churni/common';
import { ToggleEnvironment } from '../ToggleEnvironment';
import { PeriodicityPicker } from './common';
import { SEO } from '../SEO';
import { DashboardHeader } from '../DashboardLayout';
import { Overview } from './Overview';
import { Insights } from './Insights';
import { CustomersRouter } from './Customers';
import { useLocation } from '../../../../common/src';
import { PanelHeaderLink, PanelHeader, Panel, PanelBody } from './Panel';

export function NewDashboard(): React.ReactElement {
  const { location } = useLocation();
  const pathname = location.pathname;

  return (
    <>
      <SEO title={'Dashboard'} />
      <Box sx={{ position: 'relative' }}>
        <DashboardHeader title={'Dashboard'} sx={{ borderBottom: 0 }}>
          <Flex flexDirection={['row']} alignItems="baseline">
            <Box mr={3}>
              <ToggleEnvironment />
            </Box>

            <PeriodicityPicker />
          </Flex>
        </DashboardHeader>

        <Panel className={`body`}>
          <PanelHeader>
            <PanelHeaderLink
              active={!!pathname.match(/^\/dashboard\/overview/)}
              route={routes.dashboard()}
            >
              Overview
            </PanelHeaderLink>
            <PanelHeaderLink
              active={!!pathname.match(/^\/dashboard\/insights/)}
              route={routes.insights()}
            >
              Insights
            </PanelHeaderLink>
            <PanelHeaderLink
              active={!!pathname.match(/^\/dashboard\/customers/)}
              route={routes.customers()}
            >
              Customers
            </PanelHeaderLink>
          </PanelHeader>
          <PanelBody>
            <Router>
              <Overview path={'/overview'} />
              <Insights path={'/insights'} />
              <CustomersRouter path={'/customers/*'} />
              <Redirect from={'/'} to={routes.dashboard()} noThrow={true} />
            </Router>
          </PanelBody>
        </Panel>
      </Box>
    </>
  );
}
