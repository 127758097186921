import * as React from 'react';
import { Box, Text, Flex } from '@churni/styleguide';
import { DashboardCard, PieChart } from '../common';
import { navigate } from '@churni/common';
import { routes } from '@churni/common';

export function SessionsStatsCard(props: {
  resource: any;
}): React.ReactElement {
  const { resource } = props;

  const {
    data: { status, count }
  } = resource.read();

  const labels = status.map(s => s.key);
  const data = status.map(s => s.doc_count);

  const onClick = (index: number) => {
    navigate(
      routes.customers({
        filters: {
          status: labels[index]
        }
      })
    );
  };

  return (
    <DashboardCard title="Sessions Status" info="By session start date">
      <Flex alignItems={'baseline'}>
        <Box mr={1}>
          <Text variant="display4">{count}</Text>
        </Box>
        <Box>
          <Text variant="subtitle1">sessions</Text>
        </Box>
      </Flex>

      <Box mt={3}>
        <PieChart labels={labels} data={data} onClick={onClick} />
      </Box>
    </DashboardCard>
  );
}
