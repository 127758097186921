import * as React from 'react';
import { Flex, Icon, Box, Text, Link } from '@churni/styleguide';

import { SearchContext } from './SearchContext';
import { routes, navigate } from '@churni/common';
import { useSearchParams } from './useSearchParams';
import { useCustomersResourceFromContext } from './useCustomersResourceFromContext';

const mapping = {
  subLength: 'Sub. Length',
  saveReason: 'Save Reason',
  status: 'Status'
};

export function BadgeFilter(props: {
  slug: string;
  value: string;
  slugDelete: string;
}): React.ReactElement {
  const { slug, value, slugDelete } = props;
  const { search, periodicity, filters, from, to } = useSearchParams();

  // https://github.com/facebook/react/issues/17272
  const [startTransition] = React.useTransition({ timeoutMs: 5500 });

  const onDelete = () => {
    delete filters[slugDelete || slug];
    startTransition(() => {
      navigate(routes.customers({ filters, search, periodicity, from, to }));
    });
  };

  return (
    <Flex
      mr={1}
      mb={1}
      py={'4px'}
      sx={{
        alignItems: 'center',
        borderRadius: 'circle',
        bg: 'secondary',
        color: 'white',

        lineHeight: 1,
        maxWidth: 400
      }}
    >
      <Flex px={1}>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200
          }}
        >
          <Text variant={'subtitle4'}>{mapping[slug] || slug}</Text>
        </Box>
        <Box mr={'4px'}>:</Box>

        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200
          }}
        >
          <Text variant={'subtitle4'}>{value}</Text>
        </Box>
      </Flex>
      <Box sx={{ pr: 1, cursor: 'pointer' }} onClick={onDelete}>
        <Icon.X />
      </Box>
    </Flex>
  );
}

export function ActiveFilters(): React.ReactElement {
  const { customersResource } = useCustomersResourceFromContext();
  const {
    data: { questions }
  } = customersResource.read();
  const { filters } = React.useContext(SearchContext);

  if (!filters) {
    return null;
  }

  return (
    <Flex flexWrap={'wrap'}>
      {Object.entries(filters).map(([key, value]) => {
        if (key === 'subLength') {
          return <SubLengthBadge key={key} slug={key} value={value} />;
        }

        if (['questions'].includes(key)) {
          return value.map(v => {
            const question = questions.find(q => q.key === v.key);

            return (
              <BadgeFilter
                key={key}
                slug={question ? question.infos.title : v.key}
                value={v.value || v.search}
                slugDelete={key}
              />
            );
          });
        }

        if (['variables'].includes(key)) {
          return value.map(v => (
            <BadgeFilter
              key={key}
              slug={v.key}
              value={v.value || v.search}
              slugDelete={key}
            />
          ));
        }

        return <BadgeFilter key={key} slug={key} value={value} />;
      })}
    </Flex>
  );
}

export function SubLengthBadge(props: {
  slug: string;
  value: { from: string; to: string };
}) {
  const { slug, value } = props;

  if (value.from && value.to) {
    return (
      <BadgeFilter slug={slug} value={`${value.from} - ${value.to} months`} />
    );
  }

  if (value.from) {
    return <BadgeFilter slug={slug} value={`${value.from} - * months`} />;
  }

  if (value.to) {
    return <BadgeFilter slug={slug} value={`* - ${value.to} month`} />;
  }
}
