import { Environment } from './containers';
import * as React from 'react';
import { Flex, Switch, Text, Tooltip } from '@churni/styleguide';

export function ToggleEnvironment(): React.ReactElement {
  const {
    environmentID,

    toggleEnvironment
  } = Environment.useContainer();

  return (
    <Tooltip
      content={
        'Use test environment for your staging and development environments.'
      }
    >
      <Flex alignItems="center">
        <Text variant="subtitle3">Test env.</Text>
        <Switch
          className="toggle-environment"
          ml={1}
          checked={environmentID === 'testing'}
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            toggleEnvironment();
          }}
        />
      </Flex>
    </Tooltip>
  );
}
