import {
  useFirestoreDocData,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce
} from 'reactfire';

export const useDocument = useFirestoreDocData;
export const useDocumentOnce = useFirestoreDocDataOnce;

export const useCollection = useFirestoreCollectionData;
