import * as queryString from 'querystring';
import * as React from 'react';

import * as url from 'url';
import { navigate, routes } from '@churni/common';
import { LoginLayout } from './LoginLayout';
import { Link, Text } from '@churni/styleguide';
import { useAuth, useFirebase } from '@churni/fire';
import { SEO } from '../SEO';

/*
 * Callback view for the verifyEmail action from firebase.
 */
export function AuthVerifyEmail(props: {
  action: any;
  actionCode: string;
}): React.ReactElement | null {
  const { actionCode } = props;
  const auth = useAuth();
  const firebase = useFirebase();

  const [verified, setVerified] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const timeout = React.useRef<NodeJS.Timeout | null>();

  const onContinue = () => {
    const queryParameters = queryString.parse(window.location.search.slice(1));

    let { continueUrl } = queryParameters;

    if (continueUrl) {
      const parsed = url.parse(continueUrl);
      continueUrl = parsed.pathname;
    } else {
      continueUrl = routes.app();
    }

    navigate(continueUrl);
    window.location.reload();
  };

  const applyCode = async () => {
    try {
      await auth.applyActionCode(actionCode);
    } catch (err) {
      setError(err.message);
      return;
    }

    setVerified(true);

    timeout.current = setTimeout(() => {
      onContinue();
    }, 4000);
  };

  React.useEffect(() => {
    (async () => {
      await Promise.all([
        applyCode(),
        firebase.functions().httpsCallable('hubspot-updateContactOnVerify')()
      ]);
      await auth.currentUser.reload();
    })();

    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!verified && !error) {
    return null;
  }

  return (
    <>
      <SEO title={'Verify Email'} />
      <LoginLayout
        error={error}
        body={
          <Text>
            Your email has been verified. You are about to be redirected to your{' '}
            <Link variant="primary" as={'span'} onClick={onContinue}>
              dashboard.
            </Link>
          </Text>
        }
      />
    </>
  );
}
