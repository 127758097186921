import * as React from 'react';
import { createContainer } from 'unstated-next';
import { Environment as EnvironmentModel } from '@churni/core';
import { useDatabase, useDocument } from '@churni/fire';
import { useCompany } from './Company';
import { useLocation } from '../../../../common/src';
import * as querystring from 'querystring';

const demoEnv = EnvironmentModel.create({
  id: 'demo',
  appID: 'demo',
  companyID: 'demo',
  defaultFormID: 'demo'
});

function useEnvironmentState(initialState: string) {
  const { location } = useLocation();
  const qs = querystring.parse(location.search.slice(1));
  const [environmentID, setEnv] = React.useState(
    qs.environmentID || initialState
  );

  const toggleEnvironment = () => {
    if (environmentID === 'production') {
      setEnv('testing');
    } else {
      setEnv('production');
    }
  };

  return {
    environmentID,
    toggleEnvironment
  };
}

export const Environment = createContainer(useEnvironmentState);

export const useEnvironment = () => {
  const company = useCompany();
  const db = useDatabase();
  const { environmentID } = Environment.useContainer();

  const environment = useDocument<EnvironmentModel>(
    company.id === 'demo'
      ? null
      : EnvironmentModel.queryByID(db)(company.id, environmentID)
  );

  return environment || demoEnv;
};
