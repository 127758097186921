import {
  Box,
  Container,
  Dropdown,
  Flex,
  Icon,
  Menu,
  Link
} from '@churni/styleguide';

import { routes, Logo, useLocation } from '@churni/common';

import * as React from 'react';
import { getTabs } from './getTabs';

export function MobileHeader(): React.ReactElement {
  const { location } = useLocation();
  const pathname = location.pathname;

  const tabs = getTabs(pathname);
  return (
    <Box>
      <Container>
        <Flex
          height={80}
          width={12}
          alignItems={'center'}
          justifyContent={'space-between'}
          pr={2}
        >
          <Flex alignItems={'center'}>
            <Link to={routes.app()}>
              <Logo className={'header-logo'} />
            </Link>
          </Flex>
          <Dropdown toggle={<Icon.Menu />} position="south">
            <Menu sx={{ width: 400, px: 2 }}>
              {Object.values(tabs).map(tab => (
                <Menu.Item
                  key={tab.to}
                  icon={tab.icon}
                  to={tab.to}
                  color={tab.isActive ? 'primary' : 'heading'}
                >
                  {tab.title}
                </Menu.Item>
              ))}
            </Menu>
          </Dropdown>
        </Flex>
      </Container>
    </Box>
  );
}
