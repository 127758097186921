import { User } from '@churni/core';
import { Box, Button, Flex, Form, Icon, Input, Text } from '@churni/styleguide';
import { useAuth, useDatabase, useFirebase, useAuthUser } from '@churni/fire';
import * as React from 'react';
import { LoginLayout } from './LoginLayout';
import * as querystring from 'querystring';
import { Logo } from '@churni/common';
import { urls } from '../../config';
import { SEO } from '../SEO';

export function Authorize(props: any): React.ReactElement {
  const {
    location: { search }
  } = props;

  const data = querystring.parse(search.slice(1));

  const firebase = useFirebase();
  const auth = useAuth();
  const db = useDatabase();
  const user = useAuthUser();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState<null | string>(null);
  const [idToken, setIdToken] = React.useState<null | string>(null);

  React.useEffect(() => {
    if (user) {
      user.getIdToken().then(token => {
        setIdToken(token);
      });
    }
  }, [user]);

  const onEmailLogin = async () => {
    try {
      setError(null);
      setLoading(true);
      await User.loginWithEmail(auth)(email, password);
    } catch (err) {
      setError('The email you entered does not belong to any account.');
    } finally {
      setLoading(false);
    }
  };

  const onGoogleLogin = async () => {
    await User.loginWithProvider(
      auth,
      db
    )(new firebase.auth.GoogleAuthProvider());
  };

  const onSubmit = async () => {
    await onEmailLogin();
  };

  if (user) {
    return (
      <>
        <SEO title={'Authorize'} />
        <Flex
          css={{ minHeight: '100vh' }}
          alignItems="center"
          justifyContent="center"
        >
          <Box width={350}>
            <Box>
              <Logo className={'authorize-logo'} />
            </Box>
            <Box elevation={2} px={3} py={3}>
              <Text variant={'heading3'}>
                Do you accept to connect this app?
              </Text>

              <Box mt={3}>
                <form method="post" action={`${urls.app}/api/oauth/authorize`}>
                  <input type={'hidden'} name="authorization" value={idToken} />
                  <input
                    type={'hidden'}
                    name="client_id"
                    value={data.client_id}
                  />
                  <input
                    type={'hidden'}
                    name="redirect_uri"
                    value={data.redirect_uri}
                  />
                  <input
                    type={'hidden'}
                    name="response_type"
                    value={data.response_type}
                  />
                  <input
                    type={'hidden'}
                    name="grant_type"
                    value={data.grant_type}
                  />
                  <input type={'hidden'} name="state" value={data.state} />
                  <Button
                    variant="cancel"
                    type="submit"
                    name="action"
                    value="deny"
                  >
                    Deny
                  </Button>
                  <Button ml={1} type="submit" name="action" value="allow">
                    Allow
                  </Button>
                </form>
              </Box>
            </Box>
          </Box>
        </Flex>
      </>
    );
  }

  return (
    <>
      <SEO title={'Sign in'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            <Flex justifyContent="space-between" mb={1}>
              <Box>
                <Text variant="heading1">Sign in</Text>
              </Box>
            </Flex>
            <Form onSubmit={onSubmit}>
              <Box pb={2}>
                <Input
                  focus
                  bg="white"
                  placeholder="Email"
                  value={email}
                  onChangeValue={setEmail}
                />
              </Box>
              <Box pb={2}>
                <Input
                  bg="white"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChangeValue={setPassword}
                />
              </Box>
              <Flex>
                <Button
                  className="authorize-signin-email"
                  flex={1}
                  kind="primary"
                  type="submit"
                  loading={loading}
                >
                  <Text>Sign in</Text>
                </Button>
              </Flex>
            </Form>
          </Box>
        }
        footer={
          <Box color={'secondaryText'} mt={2}>
            <Flex>
              <Button
                flex={1}
                className="authorize-signin-google"
                variant="primaryOutline"
                onClick={onGoogleLogin}
                icon={<Icon.GoogleLogo />}
              >
                Sign in with Google
              </Button>
            </Flex>
          </Box>
        }
      />
    </>
  );
}
