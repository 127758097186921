import * as React from 'react';
import {
  Section,
  Box,
  Text,
  Flex,
  Suspense,
  SectionActions,
  Button
} from '@churni/styleguide';
import { Environment as EnvironmentModel } from '@churni/core';
import { ToggleEnvironment } from '../../ToggleEnvironment';
import { Environment, Setup, useCompany } from '../../containers';
import { useDatabase, useDocument } from '@churni/fire/src';

export function SettingsSetup(props: {}): React.ReactElement {
  const { openPopup } = Setup.useContainer();
  const { environmentID } = Environment.useContainer();

  return (
    <Section title={'Setup'}>
      <Flex justifyContent="space-between" flexDirection={['column', 'row']}>
        <Box>
          <Suspense>
            <SettingsSetupAppID environmentID={environmentID} />
          </Suspense>
        </Box>
        <Box mt={[2, 0]}>
          <ToggleEnvironment />
        </Box>
      </Flex>

      <SectionActions>
        <Box />
        <Button onClick={openPopup} className="settings-setup">
          View setup instructions
        </Button>
      </SectionActions>
    </Section>
  );
}

export function SettingsSetupAppID(props: {
  environmentID: string;
}): React.ReactElement {
  const db = useDatabase();
  const company = useCompany();
  const environment = useDocument(
    EnvironmentModel.queryByID(db)(company.id, props.environmentID)
  );

  return <Text>App ID: {environment.appID}</Text>;
}
