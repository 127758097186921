import * as React from 'react';
import { Section, Box, Button, SectionActions } from '@churni/styleguide';
import { useDatabase, useCollection } from '@churni/fire';
import { Member } from '@churni/core';
import { useCompany, Invite } from '../../containers';

export function SettingsMembers(): React.ReactElement {
  const company = useCompany();
  const db = useDatabase();
  const members = useCollection(Member.queryByCompany(db)(company.id));
  const { openPopup } = Invite.useContainer();

  return (
    <>
      <Section title={'Members'}>
        <Box
          sx={{ display: 'grid', gridTemplateColumns: '2fr 1fr', columnGap: 1 }}
          rowGap={1}
        >
          <Box>
            <b>Email</b>
          </Box>
          <Box>
            <b>Role</b>
          </Box>
          {members.map(member => (
            <>
              <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {member.email}
              </Box>
              <Box>{member.role}</Box>
            </>
          ))}
        </Box>

        <SectionActions>
          <Box />
          <Button onClick={openPopup} className="settings-member-invite">
            Invite
          </Button>
        </SectionActions>
      </Section>
    </>
  );
}
