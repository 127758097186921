import { Box, Flex, Link, Text, Container } from '@churni/styleguide';
import { routes, Logo } from '@churni/common';
import * as React from 'react';

export function LoginLayout(props: {
  body: React.ReactElement;
  footer: React.ReactElement;
  error?: string;
}): React.ReactElement {
  const { error, body, footer } = props;

  return (
    <Flex
      css={{ minHeight: '100vh' }}
      alignItems="center"
      justifyContent="center"
    >
      <Container maxWidth={450}>
        <Box>
          <Box mb={1}>
            <Link as="a" href={routes.toWebsiteURL(routes.root())}>
              <Logo className={'login-logo'} />
            </Link>
          </Box>
          <Box color="error">
            <Text>{error}</Text>
          </Box>
          <Box elevation={2} px={4} py={5} sx={{ borderRadius: 'rounded' }}>
            {body}
          </Box>
          <Box px={2}>{footer}</Box>
        </Box>
      </Container>
    </Flex>
  );
}
