/* eslint-disable @typescript-eslint/camelcase */
import {
  Button,
  Modal,
  Popup,
  Text,
  Box,
  Grid,
  Flex,
  Switch,
  Icon,
  Container,
  Slider
} from '@churni/styleguide';
import { plans, Plan, pricing } from '@churni/common';

import * as React from 'react';

import { useCompany } from '../../containers';
import { useAuthUser, useFirebase } from '@churni/fire';

export const UpgradeModal = (props: {
  onClose: () => void;
}): React.ReactElement => {
  const { onClose } = props;
  const user = useAuthUser();
  const firebase = useFirebase();

  const company = useCompany();

  const steps = [50, 100, 250, 500, 1000, '1000+'];
  const [loading, setLoading] = React.useState(false);

  const [value, setValue] = React.useState(1);
  const [confirmation, setConfirmation] = React.useState(false);
  const [error, setError] = React.useState(null);

  const isUpdate = company.billing.subscriptionID;

  const [isYearly, setYearly] = React.useState(
    !isUpdate ? false : company.billing.periodicity === 'yearly'
  );

  const [product, setProduct] = React.useState('pro');

  const pricingForProduct = pricing[steps[value]][product];

  const pricingPlan = pricingForProduct.monthly
    ? pricingForProduct[isYearly ? 'yearly' : 'monthly']
    : null;

  const cta = isUpdate ? 'Change plan' : 'Upgrade';

  const updateDisabled =
    isUpdate && pricingPlan && company.billing.planID === pricingPlan.paddleID;

  const onUpgrade = async () => {
    const passthrough = JSON.stringify({
      product: 'pro',
      quantity: steps[value],
      companyID: company.id,
      periodicity: isYearly ? 'yearly' : 'monthly'
    });

    if (company.billing.subscriptionID) {
      if (window.confirm('Please confirm to update your subscription')) {
        setLoading(true);
        const {
          data: { status }
        } = await firebase
          .functions()
          .httpsCallable('billing-updateSubscription')({
          companyID: company.id,
          planID: pricingPlan.paddleID,
          passthrough
        });

        setLoading(false);
        if (status === 'ok') {
          setConfirmation(true);
          dataLayer.push({
            event: 'subscribe'
          });
        } else {
          setError(
            'An error has occured, please try again or contact us if the issue persists'
          );
        }
      }
    } else {
      window.Paddle.Checkout.open({
        product: pricingPlan.paddleID,
        email: user.email,
        locale: 'en',
        passthrough,
        successCallback: () => {
          setConfirmation(true);
        }
      });
    }
  };

  if (confirmation) {
    return (
      <Popup onClose={onClose}>
        <Modal>
          <Modal.Header>{cta}</Modal.Header>
          <Modal.Body>
            <Text variant={'subtitle3'}>
              Thank you! Your subscription has been successfuly updated.
            </Text>
          </Modal.Body>

          <Modal.Footer>
            <Box />
            <Button
              variant={'cancel'}
              onClick={onClose}
              className="upgrade-cancel"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Popup>
    );
  }

  return (
    <Popup onClose={onClose}>
      <Modal>
        <Flex
          justifyContent="space-between"
          sx={{ borderBottom: 1, borderColor: 'border', pb: 1 }}
          flexDirection={['column', 'row']}
        >
          <Box>
            <Text variant={'heading1'}>{cta}</Text>
          </Box>
          {company.billing.planInterval !== 'year' && (
            <Box>
              <Flex alignItems="center">
                <Text variant={'overline'}>Billed yearly</Text>
                <Switch
                  ml={1}
                  checked={isYearly}
                  onClick={() => setYearly(!isYearly)}
                  className="upgrade-yearly-button"
                />
              </Flex>
              <Box>
                <Text
                  color="secondary"
                  variant="body3"
                  sx={{ textAlign: 'left' }}
                >
                  Save 2 months!
                </Text>
              </Box>
            </Box>
          )}
        </Flex>

        <Modal.Body py={2}>
          {error && (
            <Box mb={2}>
              <Text color="red">{error}</Text>
            </Box>
          )}
          <Container sx={{ position: 'relative', maxWidth: 500 }} mb={5}>
            <Box>
              <Text variant="heading3">
                How many cancel sessions per month?
              </Text>
            </Box>
            <Slider
              className="upgrade-slider"
              type="range"
              min={0}
              max={5}
              onChangeValue={setValue}
              value={value}
            />
            <Box
              sx={{
                position: 'absolute',
                left: `${(100 / 9) * value}%`,
                transform: 'translate(-50%, 0%)',
                whiteSpace: 'nowrap'
              }}
            >
              {steps[value]} sessions
            </Box>
          </Container>

          <Grid
            columns={[1, 2]}
            columnGap={2}
            rowGap={2}
            pb={3}
            sx={{ margin: 'auto' }}
          >
            {Object.values(plans).map(info => {
              return (
                <PricingCard
                  onClick={(pricingPlan, newProduct) => {
                    setProduct(newProduct);
                  }}
                  isYearly={isYearly}
                  active={info.key === product}
                  plan={info.key}
                  price={pricing[steps[value]][info.key]}
                  key={info.key}
                  details={info}
                  disabled={info.key === 'premium' || info.key === 'starter'}
                />
              );
            })}
          </Grid>
          <Text variant="body3" color="secondaryText">
            {`Payments and credit card data are handled by Paddle (our reseller) and never reach our servers. `}
          </Text>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant={'cancel'}
            onClick={onClose}
            className="upgrade-cancel"
          >
            Cancel
          </Button>

          <Button
            ml={2}
            className="upgrade-cta paddle_button"
            onClick={onUpgrade}
            data-product="588433"
            href="#!"
            variant={'primary'}
            loading={loading}
            as="a"
            disabled={!pricingPlan || updateDisabled}
          >
            {pricingPlan ? <>{cta}</> : <>Contact us</>}
          </Button>
        </Modal.Footer>
      </Modal>
    </Popup>
  );
};

export function PricingCard(props: {
  isYearly: boolean;
  details: Plan;
  active: boolean;
  plan: string;
  disabled: boolean;
  price:
    | string
    | {
        monthly: {
          price: number;
          id: string;
        };
        yearly: {
          price: number;
          id: string;
        };
      };

  onClick: (pricePlan: { id: string; price: number }, plan: string) => void;
}): React.ReactElement {
  const { active, onClick, details, plan, isYearly, price, disabled } = props;

  const features = details.features;
  const pricePlan = price.monthly
    ? isYearly
      ? price.yearly
      : price.monthly
    : null;
  const [paddlePrice, setPaddlePrice] = React.useState(null);

  React.useEffect(() => {
    if (pricePlan && pricePlan.paddleID) {
      Paddle.Product.Prices(pricePlan.paddleID, price => {
        setPaddlePrice(price);
      });
    } else {
      setPaddlePrice(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricePlan && pricePlan.paddleID]);

  return (
    <Box
      className={`upgrade-plan-${plan}`}
      onClick={() => {
        if (!disabled) {
          onClick(pricePlan, plan);
        }
      }}
      px={3}
      pt={2}
      sx={{
        borderRadius: 'rounded',
        cursor: !disabled ? 'pointer' : 'inherit',
        border: '1',
        borderColor: active ? 'primary' : 'border',
        opacity: disabled ? 0.5 : 1
      }}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'border', pb: 1 }}>
        <Flex color={active ? 'primary' : 'text'}>
          <Box>
            <Flex alignItems={'center'}>
              <Text variant="heading3">{details.title}</Text>
              <Box ml={1}>
                <Text>
                  {paddlePrice ? (
                    <>
                      {paddlePrice.price.gross}/
                      {paddlePrice.recurring.subscription.type}
                    </>
                  ) : null}
                </Text>
              </Box>
              {disabled && (
                <Box ml={'auto'} mr={-3}>
                  <Text color="secondary" variant="body3">
                    coming soon
                  </Text>
                </Box>
              )}
            </Flex>
            <Box>
              {isYearly && (
                <Text color="secondaryText" variant="body3">
                  {' '}
                  billed yearly, 12 months commitment
                </Text>
              )}
            </Box>
          </Box>
        </Flex>
      </Box>

      {features && (
        <Box>
          <Box as={'ul'} sx={{ listStyleType: 'none', px: 0, pb: 1 }}>
            {features.map((feature, key) => (
              <Box as={'li'} key={key}>
                <Icon.Check /> <Text variant="body3">{feature}</Text>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}
