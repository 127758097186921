/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { Box, Text, Flex } from '@churni/styleguide';
import { PieChart, DashboardCard } from '../common';
import { routes, navigate } from '@churni/common';

export function SaveReasonsCard(props: { resource: any }): React.ReactElement {
  const { resource } = props;

  const {
    data: { saveReason, status }
  } = resource.read();

  const saved = status.find(s => s.key === 'saved') || { doc_count: 0 };
  const labels = saveReason.map(reason => reason.key);
  const data = saveReason.map(reason => reason.doc_count);

  const onClick = (index: number) => {
    navigate(
      routes.customers({
        filters: {
          saveReason: labels[index]
        }
      })
    );
  };

  if (!saved.doc_count) {
    return null;
  }

  return (
    <DashboardCard title="Save Reasons" info="By session start date">
      <Flex alignItems={'baseline'}>
        <Box mr={1}>
          <Text variant="display4" sx={{ whiteSpace: 'nowrap' }}>
            {saved.doc_count}
          </Text>
        </Box>
        <Box>
          <Text variant={'subtitle1'} sx={{ whiteSpace: 'nowrap' }}>
            customers saved
          </Text>
        </Box>
      </Flex>

      <Box mt={3}>
        <PieChart labels={labels} data={data} onClick={onClick} />
      </Box>
    </DashboardCard>
  );
}
