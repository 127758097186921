import * as React from 'react';
import { Box, Flex, Link, Text, Loading } from '@churni/styleguide';

import { Suspense } from '../../../../styleguide/src/components';

export function Panel(props: { children: React.ReactElement }) {
  const { children, ...rest } = props;

  return (
    <Box elevation={1} py={3} px={[2, 2, 6]} {...rest}>
      {children}
    </Box>
  );
}

export function PanelHeader(props: { children: React.ReactElement }) {
  const { children } = props;

  return (
    <Flex sx={{ borderBottom: 1, borderColor: 'border', pb: 2 }}>
      {children}
    </Flex>
  );
}
export function PanelBodyLoading() {
  return (
    <Box my={9}>
      <Loading />
    </Box>
  );
}

export function PanelBody(props: { children: React.ReactElement }) {
  const { children } = props;

  return (
    <Suspense fallback={<PanelBodyLoading />}>
      <Box py={3}>{children}</Box>
    </Suspense>
  );
}

export function PanelHeaderLink(props: {
  active: boolean;
  route: string;
  children: string;
}) {
  const { active, route, children } = props;

  return (
    <Box
      mr={3}
      sx={{
        color: active ? 'primary' : 'text',
        position: 'relative'
      }}
    >
      <Link to={route}>
        <Text variant="subtitle3">{children}</Text>
      </Link>
      {active && (
        <Box
          sx={{
            width: '100%',
            height: 1,
            borderBottom: 1,
            borderColor: 'primary',
            position: 'absolute',
            top: '40px'
          }}
        />
      )}
    </Box>
  );
}
