import * as React from 'react';
import { useFirebase, functionsResource } from '@churni/fire';
import { PeriodicityParams } from './PeriodicityPicker';
import { useCompany, Environment } from '../../containers';

export function useCustomersResource(props: {
  periodicityParams: PeriodicityParams;
  from: string;
  to: string;
  offset: number;
  search: string;
  filters: {
    status: string;
    saveReason: string;
    subLength: {
      from: string;
      to: string;
    };
    questions: { key: string; value: string }[];
    variables: { key: string; value: string }[];
  };
}) {
  const [startTransition] = React.useTransition({ timeoutMs: 5200 });
  const { startAt, endAt, periodicity } = props.periodicityParams;
  const { filters, search = '', from, to, offset } = props;

  const firebase = useFirebase();
  const company = useCompany();

  const { environmentID: originalEnvironment } = Environment.useContainer();

  const environmentID = company.id === 'demo' ? 'demo' : originalEnvironment;
  const companyID = company.id;

  const [customersResource, setCustomersResource] = React.useState(
    functionsResource(firebase, 'search-sessions', {
      type: 'customers',
      companyID,
      environmentID,
      startAt: props.from || startAt.toISOString(),
      endAt: props.to || endAt.toISOString(),
      filters,
      search,
      offset
    })
  );

  React.useEffect(
    () => {
      startTransition(() => {
        setCustomersResource(
          functionsResource(firebase, 'search-sessions', {
            type: 'customers',
            companyID,
            environmentID,
            startAt: from || startAt.toISOString(),
            endAt: to || endAt.toISOString(),
            filters,
            search,
            offset
          })
        );
      });
    }, // eslint-disable-next-line
    [
      periodicity,
      startAt,
      endAt,
      JSON.stringify(filters),
      search,
      to,
      from,
      offset
    ]
  );

  return {
    customersResource,
    setCustomersResource
  };
}
