/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import { Box, Text } from '@churni/styleguide';
import { LineChart, DashboardCard } from '../common';
import { routes, navigate } from '@churni/common';

export function SavePerformanceCard(props: {
  resource: any;
  interval: string;
}): React.ReactElement {
  const { resource, interval } = props;

  const {
    data: { canceled = [], status }
  } = resource.read();

  const saved = status.find(s => s.key === 'saved') || { doc_count: 0 };
  const cancel = status.find(s => s.key === 'canceled') || { doc_count: 0 };

  const performance =
    saved.doc_count > 0
      ? (saved.doc_count / (saved.doc_count + cancel.doc_count)) * 100
      : 0;

  const onClick = (index: number) => {
    const from = canceled[index].key_as_string.slice(0, 10);
    const to = canceled[index + 1]
      ? canceled[index + 1].key_as_string.slice(0, 10)
      : null;

    navigate(
      routes.customers({
        from,
        to
      })
    );
  };

  const labels = canceled.map(s =>
    new Date(s.key_as_string).toLocaleDateString('en-US', {
      day: '2-digit',
      month: '2-digit'
    })
  );

  const data = canceled.map(s => {
    const saveInterval = s.status.buckets.find(s => s.key === 'saved') || {
      doc_count: 0
    };
    const cancelInterval = s.status.buckets.find(s => s.key === 'canceled') || {
      doc_count: 0
    };

    return (
      (saveInterval.doc_count /
        (saveInterval.doc_count + cancelInterval.doc_count)) *
      100
    ).toFixed(1);
  });

  return (
    <DashboardCard
      className={'saveperformance'}
      title="Save performance"
      info={`By average ${interval} save rate`}
      width={'100%'}
    >
      <Text variant="display4">{performance.toFixed(1)}%</Text>
      <Box mt={6}>
        <LineChart labels={labels} data={data} onClick={onClick} />
      </Box>
    </DashboardCard>
  );
}
