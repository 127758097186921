import * as React from 'react';
import { FormState } from '../containers';
import { globalHistory, navigate } from '@reach/router';

export function useAlertOnExit(isDemo: boolean) {
  const { hasChanged, setChanged } = FormState.useContainer();

  React.useEffect(() => {
    if (hasChanged) {
      setChanged(true);
      if (!isDemo) {
        globalHistory.navigate = (...props) => {
          if (
            window.confirm(
              `Changes you have made won't be be saved. Are you sure?`
            )
          ) {
            navigate(...props);
            globalHistory.navigate = navigate;
          }
        };
        window.onbeforeunload = () => {
          return `Changes you have made won't be be saved.`;
        };
      }
    } else {
      setChanged(false);
      window.onbeforeunload = null;
      globalHistory.navigate = navigate;
    }

    return () => {
      window.onbeforeunload = null;
      globalHistory.navigate = navigate;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasChanged, isDemo]);
}
