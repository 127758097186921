import { Icon } from '@churni/styleguide';
import { routes } from '@churni/common';
import * as React from 'react';

export const getTabs = (pathname: string) => ({
  dashboard: {
    title: 'Dashboard',
    icon: <Icon.Grid />,
    to: routes.dashboard(),
    isActive: !!pathname.match(/^\/dashboard/)
  },

  forms: {
    title: 'Cancel Flow',
    icon: <Icon.File />,
    to: routes.flow(),
    isActive: !!pathname.match(/^\/cancel/),
    displayed: true
  },
  integrations: {
    title: 'Integrations',
    icon: <Icon.Box />,
    to: routes.integrations(),
    isActive: !!pathname.match(/^\/integrations/),
    displayed: true
  },
  settings: {
    title: 'Settings',
    icon: <Icon.Sliders />,
    to: routes.settings(),
    isActive: !!pathname.match(/^\/settings/),
    displayed: true
  }
});
