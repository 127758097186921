import * as React from 'react';
import { useAuth } from '@churni/fire';
import { navigate, routes } from '@churni/common';
import { Box, Link, Text, Button, Form } from '@churni/styleguide';
import { LoginLayout } from './LoginLayout';
import { SEO } from '../SEO';

/*
 * Action handler when user clicked on the email to recover
 * his previous email.
 */
export function AuthRecoverEmail(props: {
  action: any;
  actionCode: string;
}): React.ReactElement | null {
  const { actionCode } = props;
  const auth = useAuth();

  const [verified, setVerified] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        await auth.checkActionCode(actionCode);
        setVerified(true);
      } catch (err) {
        setError(err.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    try {
      await auth.applyActionCode(actionCode);

      setSuccess(true);

      setTimeout(() => {
        navigate(routes.app());
      }, 5000);
    } catch (err) {
      setError(err.message);
    }
  };

  if (!verified && !error) {
    return null;
  }

  return (
    <>
      <SEO title={'Recover email'} />
      <LoginLayout
        error={error}
        body={
          <Box>
            <>
              {success ? (
                <Text>
                  Your email has been recovered. You are about to be redirected
                  to your <Link to={routes.app()}>dashboard.</Link>
                </Text>
              ) : (
                <Form onSubmit={onSubmit}>
                  <Text>Are you sure to recover your previous email ?</Text>

                  <Button>Recover my previous email</Button>
                </Form>
              )}
            </>
          </Box>
        }
      />
    </>
  );
}
